exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".components-Loader-styles___loaderLayout--3kSm4 {\n  text-align: center;\n  position: relative;\n}\n\n  .components-Loader-styles___loaderLayout--3kSm4 .components-Loader-styles___s-circular-progress--3nZv7 {\n    position: absolute;\n    top: 30px;\n  }\n", "", {"version":3,"sources":["/tmp/build_c0b3ce23/src/components/Loader/styles.pcss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;CACpB;;EAEC;IACE,mBAAmB;IACnB,UAAU;GACX","file":"styles.pcss","sourcesContent":[".loaderLayout {\n  text-align: center;\n  position: relative;\n}\n\n  .loaderLayout .s-circular-progress {\n    position: absolute;\n    top: 30px;\n  }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"loaderLayout": "components-Loader-styles___loaderLayout--3kSm4",
	"s-circular-progress": "components-Loader-styles___s-circular-progress--3nZv7",
	"sCircularProgress": "components-Loader-styles___s-circular-progress--3nZv7"
};