import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TravelPolicyTableRows from './TravelPolicyTableRows';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Loader from 'components/Loader/Loader';

const styles = require('./styles.pcss');

type Props = {
  isUpdatedTravelPolicyEnabled: boolean;
  isLoading: boolean;
  flightPolicy: any;
  hotelPolicy: any;
};

const defaultTexts = {
  na: '-',
  allBooking: 'All Booking',
  outOfPolicy: 'Out of Policy',
};

const advanceBooking: any = {
  name: 'Notifications',
  description: 'Bookings for which managers are notified about.',
};

const hardCap: any = {
  name: 'Approvals',
  description: 'Bookings that require manager approval.',
};

class NotificationsAndApprovals extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      flightPolicy,
      hotelPolicy,
      isLoading,
      isUpdatedTravelPolicyEnabled,
    } = this.props;

    let flightNotifications = defaultTexts.na,
      hotelNotifications = defaultTexts.na,
      flightApproval = defaultTexts.na,
      hotelApproval = defaultTexts.na;

    if (!isUpdatedTravelPolicyEnabled) {
      const alwaysSendEmail = 'rules.always.actions.sendEmail.value';
      const alwaysNotifications =
        'rules.alwaysNotifications.actions.sendEmail.value';

      const sendOverBudgetEmail = 'rules.overBudget.actions.sendEmail.value';
      const sendOverBudgetFlexEmail =
        'rules.overBudgetFlex.actions.sendEmail.value';

      const isFlightAlwaysSendNotifications =
        _.get(flightPolicy, alwaysSendEmail, false) ||
        _.get(flightPolicy, alwaysNotifications, false);

      const isFlightSendOverBudgetEmail =
        _.get(flightPolicy, sendOverBudgetEmail, false) ||
        _.get(flightPolicy, sendOverBudgetFlexEmail, false);

      if (isFlightAlwaysSendNotifications) {
        flightNotifications = defaultTexts.allBooking;
      }

      if (!isFlightAlwaysSendNotifications && isFlightSendOverBudgetEmail) {
        flightNotifications = defaultTexts.outOfPolicy;
      }

      const isHotelAlwaysSendNotifications =
        _.get(hotelPolicy, alwaysSendEmail, false) ||
        _.get(hotelPolicy, alwaysNotifications, false);

      const isHotelSendOverBudgetEmail =
        _.get(hotelPolicy, sendOverBudgetEmail, false) ||
        _.get(hotelPolicy, sendOverBudgetFlexEmail, false);

      if (isHotelAlwaysSendNotifications) {
        hotelNotifications = defaultTexts.allBooking;
      }

      if (!isHotelAlwaysSendNotifications && isHotelSendOverBudgetEmail) {
        hotelNotifications = defaultTexts.outOfPolicy;
      }

      const overBudgetApproval = 'rules.overBudget.actions.approval';
      const overBudgetFlexApproval = 'rules.overBudgetFlex.actions.approval';
      const alwaysApproval = 'rules.always.actions.approval.value';
      const alwaysNotificationsApproval =
        'rules.alwaysNotifications.actions.approval.value';

      const isFlightAlwaysApproval =
        _.get(flightPolicy, alwaysApproval, false) ||
        _.get(flightPolicy, alwaysNotificationsApproval, false);

      const isFlightOverBudgetApproval =
        _.get(flightPolicy, overBudgetApproval, false) ||
        _.get(flightPolicy, overBudgetFlexApproval, false);

      if (isFlightAlwaysApproval) {
        flightApproval = defaultTexts.allBooking;
      }

      if (!isFlightAlwaysApproval && isFlightOverBudgetApproval) {
        flightApproval = defaultTexts.outOfPolicy;
      }

      const isHotelAlwaysApproval =
        _.get(hotelPolicy, alwaysApproval, false) ||
        _.get(hotelPolicy, alwaysNotificationsApproval, false);

      const isHotelOverBudgetApproval =
        _.get(hotelPolicy, overBudgetApproval, false) ||
        _.get(hotelPolicy, overBudgetFlexApproval, false);

      if (isHotelAlwaysApproval) {
        hotelApproval = defaultTexts.allBooking;
      }

      if (!isHotelAlwaysApproval && isHotelOverBudgetApproval) {
        hotelApproval = defaultTexts.outOfPolicy;
      }
    }

    if (isUpdatedTravelPolicyEnabled) {
      const flight = flightPolicy?.policyRules?.flight;
      const hotel = flightPolicy?.policyRules?.hotel;

      const isFlightAlwaysSendNotifications =
        flight?.notifications?.sendEmail || flight?.approvals?.sendEmail;
      if (isFlightAlwaysSendNotifications) {
        flightNotifications = defaultTexts.allBooking;
      }

      const isFlightAlwaysApproval =
        flight?.notifications?.approval || flight?.approvals?.approval;
      if (isFlightAlwaysApproval) {
        flightApproval = defaultTexts.allBooking;
      }

      if (flight?.overBudget || flight?.overBudgetFlex) {
        if (
          !isFlightAlwaysSendNotifications &&
          (flight.overBudget?.sendEmail || flight.overBudgetFlex?.sendEmail)
        ) {
          flightNotifications = defaultTexts.outOfPolicy;
        }

        if (
          !isFlightAlwaysApproval &&
          (flight.overBudget?.approval || flight.overBudgetFlex?.approval)
        ) {
          flightApproval = defaultTexts.outOfPolicy;
        }
      }

      const isHotelAlwaysSendNotifications =
        hotel?.notifications?.sendEmail || hotel?.approvals?.sendEmail;
      if (isHotelAlwaysSendNotifications) {
        hotelNotifications = defaultTexts.allBooking;
      }

      const isHotelAlwaysApproval =
        hotel?.notifications?.approval || hotel?.approvals?.approval;
      if (isHotelAlwaysApproval) {
        hotelApproval = defaultTexts.allBooking;
      }

      if (hotel?.overBudget) {
        if (
          !isHotelAlwaysSendNotifications &&
          (hotel.overBudget?.sendEmail || hotel.overBudgetFlex?.sendEmail)
        ) {
          hotelNotifications = defaultTexts.outOfPolicy;
        }

        debugger;
        if (
          !isHotelAlwaysApproval &&
          (hotel.overBudget?.approval || hotel.overBudgetFlex?.approval)
        ) {
          hotelApproval = defaultTexts.outOfPolicy;
        }
      }
    }

    return (
      <Paper
        className={isLoading ? cx(styles.paper, styles.loading) : styles.paper}
      >
        <div className={styles.policyRules}>
          <h2>Notifications and Approvals</h2>
          <div>
            <Loader visible={isLoading} />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="right"
                  className={cx(styles.tableCell, styles.header)}
                ></TableCell>
                <TableCell
                  align="center"
                  className={cx(styles.tableCell, styles.header)}
                >
                  Flight
                </TableCell>
                <TableCell
                  align="center"
                  className={cx(styles.tableCell, styles.header)}
                >
                  Lodging
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TravelPolicyTableRows
                description
                key={2}
                text={advanceBooking}
                flight={flightNotifications}
                hotel={hotelNotifications}
              />
              <TravelPolicyTableRows
                description
                key={4}
                text={hardCap}
                flight={flightApproval}
                hotel={hotelApproval}
              />
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

export default NotificationsAndApprovals;
