exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".components-TbButton-styles___tbButton--qyojl {\n  border-radius: 6px;\n  color: #4f84fa;\n  text-align: center;\n  border: 1px solid #3189fb;\n  padding: 15px;\n  font-size: 17px;\n}\n  .components-TbButton-styles___tbButton--qyojl:hover {\n    cursor: pointer;\n  }\n", "", {"version":3,"sources":["/tmp/build_c0b3ce23/src/components/TbButton/styles.pcss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,0BAA0B;EAC1B,cAAc;EACd,gBAAgB;CACjB;EACC;IACE,gBAAgB;GACjB","file":"styles.pcss","sourcesContent":[".tbButton {\n  border-radius: 6px;\n  color: #4f84fa;\n  text-align: center;\n  border: 1px solid #3189fb;\n  padding: 15px;\n  font-size: 17px;\n}\n  .tbButton:hover {\n    cursor: pointer;\n  }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"tbButton": "components-TbButton-styles___tbButton--qyojl"
};