import React, { Component } from 'react';
import cx from 'classnames';
import _ from 'lodash-es';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import Loader from 'components/Loader/Loader';
import CustomSelect from 'components/Inputs/Select/Select';
const sizeOfCompanyOptions = require('../../helpers/sizeOfCompanyOptions.js');
const requiredRegionCountries = [
  'United States',
  'Canada',
  'Australia',
  'Brazil',
  'Japan',
  'Mexico',
];
const requiredPostalCountries = require('../../helpers/requiredPostalCountries.js');
import AutoSuggest from 'components/Inputs/AutoSuggest/AutoSuggest';
import { states } from '../../helpers/States.js';
import { provinces } from '../../helpers/Provinces.js';
import { countries } from '../../helpers/Countries.js';

const styles = require('./styles.pcss');

type Props = {
  open: boolean;
  onClose: any;
  onSubmit: any;
  company: any;
  saveCompanyStatus: any;
  resetSaveCompanyStatus: any;
  onSuccess: any;
  isLoading: any;
};

type State = {
  name: string;
  legalName: string;
  primaryDomain: string;
  nameError: string;
  legalNameError: string;
  primaryDomainError: string;
  companyCode: string;
  codeError: string;
  companySize: any;
  country: any;
  postalCode: any;
  zipCodeRequired: Boolean;
  region: string;
  city: string;
  streetAddress: string;
  streetAddress2: string;
};

class CompanyDetailsPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let size = _.get(
      _.find(sizeOfCompanyOptions.sizeOptions, [
        'min',
        _.get(this.props, 'company.size.min', ''),
      ]),
      'id',
      '',
    );

    this.state = {
      name: _.get(this.props, 'company.name', ''),
      legalName: _.get(this.props, 'company.legalName', '') || '',
      primaryDomain: _.get(this.props, 'company.primaryDomain', ''),
      nameError: '',
      legalNameError: '',
      primaryDomainError: '',
      companyCode: _.get(this.props, 'company.companyCode', ''),
      codeError: '',
      companySize: _.isEmpty(size) ? '' : size,
      country: _.get(this.props, 'company.address.country', ''),
      postalCode: _.get(this.props, 'company.address.zip', ''),
      zipCodeRequired: true,
      region: _.get(this.props, 'company.address.state', ''),
      city: _.get(this.props, 'company.address.city', ''),
      streetAddress: _.get(this.props, 'company.address.street', ''),
      streetAddress2: _.get(this.props, 'company.address.street2', ''),
    };
  }

  componentWillReceiveProps = (nextProps: Props) => {
    if (
      _.get(nextProps.saveCompanyStatus, 'createCompanyAttempt', false) === true
    ) {
      if (nextProps.saveCompanyStatus.success === false) {
        this.setState({
          nameError: _.get(nextProps.saveCompanyStatus, 'errors.name', ''),
          legalNameError: _.get(nextProps.saveCompanyStatus, 'errors.legalName', ''),
          primaryDomainError: _.get(
            nextProps.saveCompanyStatus,
            'errors.primaryDomain',
            '',
          ),
          codeError: _.get(
            nextProps.saveCompanyStatus,
            'errors.companyCode',
            '',
          ),
        });
      } else if (nextProps.saveCompanyStatus.success === true) {
        let size = _.get(
          _.find(sizeOfCompanyOptions.sizeOptions, [
            'min',
            _.get(nextProps, 'company.size.min', ''),
          ]),
          'id',
          '',
        );

        this.setState({
          companyCode: _.get(nextProps, 'company.companyCode', ''),
          primaryDomain: _.get(nextProps, 'company.primaryDomain', ''),
          name: _.get(nextProps, 'company.name', ''),
          legalName: _.get(nextProps, 'company.legalName', ''),
          companySize: _.isEmpty(size) ? '' : size,
        });

        this.props.resetSaveCompanyStatus();
        this.props.onSuccess();
      }
    }
  };

  renderLoading = () => {
    if (_.get(this.props, 'isLoading', false) === true) {
      return (
        <div className={styles.infoLoader}>
          <Loader visible={true} />
        </div>
      );
    }

    return null;
  };

  onChangeCountryAuto = (country: any) => {
    let zipCodeRequired =
      requiredPostalCountries.requiredPostalCountries.includes(country);

    this.setState({
      region: '',
      country: country,
      zipCodeRequired,
    });
  };

  onChangeRegion = (region: any) => {
    this.setState({
      region: region,
    });
  };

  onChangeRegionText = (event: any) => {
    this.setState({
      region: event.target.value,
    });
  };

  onChangePostalCode = (event: any) => {
    this.setState({
      postalCode: event.target.value,
    });
  };

  onChangeStreetAddress = (event: any) => {
    this.setState({
      streetAddress: event.target.value,
    });
  };

  onChangeStreetAddress2 = (event: any) => {
    this.setState({
      streetAddress2: event.target.value,
    });
  };

  onChangeCity = (event: any) => {
    this.setState({
      city: event.target.value,
    });
  };

  onDeleteStreetAddress = () => {
    this.setState({
      streetAddress: '',
    });
  };

  onDeleteStreetAddress2 = () => {
    this.setState({
      streetAddress2: '',
    });
  };

  onDeleteCity = () => {
    this.setState({
      city: '',
    });
  };

  onDeletePostalCode = () => {
    this.setState({
      postalCode: '',
    });
  };

  onDeleteRegion = () => {
    this.setState({
      region: '',
    });
  };

  renderZipCode = () => {
    if (this.state.zipCodeRequired) {
      return (
        <div className={styles.inputWrapper}>
          <TextFieldWithClear
            onChange={this.onChangePostalCode}
            fullWidth
            label="Zip Code"
            variant="outlined"
            margin="normal"
            value={this.state.postalCode}
            onClear={this.onDeletePostalCode}
            disabled={this.props.isLoading}
          />
        </div>
      );
    }

    return null;
  };

  renderRegion = () => {
    if (
      this.state.country == 'United States' ||
      this.state.country == 'Canada'
    ) {
      return (
        <div className={styles.inputWrapper}>
          <AutoSuggest
            className={styles.dropdownContainer}
            onChange={(value: any) => {
              this.onChangeRegion(value);
            }}
            label={this.state.country == 'Canada' ? 'Province' : 'State'}
            returnAll={true}
            value={_.get(this.state, 'region.label', this.state.region)}
            dataSet={this.state.country == 'Canada' ? provinces : states}
            disabled={this.props.isLoading}
          />
        </div>
      );
    }

    return (
      <div className={styles.inputWrapper}>
        <TextFieldWithClear
          onChange={this.onChangeRegionText}
          fullWidth
          label="State/Province/Region"
          variant="outlined"
          margin="normal"
          value={this.state.region}
          onClear={this.onDeleteRegion}
          disabled={this.props.isLoading}
          id="zipcode-popup"
        />
      </div>
    );
  };

  onSubmit = () => {
    let size = _.find(sizeOfCompanyOptions.sizeOptions, [
      'id',
      this.state.companySize,
    ]);
    size = _.pick(size, ['min', 'max']);

    this.props.onSubmit({
      id: _.get(this.props, 'company.id', null),
      name: this.state.name,
      legalName: this.state.legalName,
      primaryDomain: this.state.primaryDomain,
      companyCode: this.state.companyCode,
      size: size,
      street: this.state.streetAddress,
      street2: this.state.streetAddress2,
      city: this.state.city,
      country: this.state.country,
      zip: this.state.zipCodeRequired ? this.state.postalCode : '',
      state: _.get(this.state, 'region.value', this.state.region),
      additionalDomains: _.get(this.props.company, 'additionalDomains'),
      notes: _.get(this.props.company, 'notes'),
    });
  };
  onClose = () => {
    let size = _.get(
      _.find(sizeOfCompanyOptions.sizeOptions, [
        'min',
        _.get(this.props, 'company.size.min', ''),
      ]),
      'id',
      '',
    );

    this.setState({
      name: _.get(this.props, 'company.name', ''),
      legalName: _.get(this.props, 'company.legalName', ''),
      primaryDomain: _.get(this.props, 'company.primaryDomain', ''),
      nameError: '',
      legalNameError: '',
      primaryDomainError: '',
      companyCode: _.get(this.props, 'company.companyCode', ''),
      codeError: '',
      companySize: _.isEmpty(size) ? '' : size,
    });

    this.props.resetSaveCompanyStatus();
    this.props.onClose();
  };

  onDeleteName = () => {
    this.setState({
      name: '',
      nameError: '',
    });
  };

  onDeleteLegalName = () => {
    this.setState({
      legalName: '',
      legalNameError: '',
    });
  };

  onDeleteCompanyCode = () => {
    this.setState({
      companyCode: '',
      codeError: '',
    });
  };

  onDeletePrimaryDomain = () => {
    this.setState({
      primaryDomain: '',
      primaryDomainError: '',
    });
  };

  onChangeName = (event: any) => {
    this.setState({
      name: event.target.value,
      nameError: '',
    });
  };

  onChangeLegalName = (event: any) => {
    this.setState({
      legalName: event.target.value,
      legalNameError: '',
    });
  };

  onChangeCompanyCode = (event: any) => {
    this.setState({
      companyCode: event.target.value,
      codeError: '',
    });
  };

  onChangePrimaryDomain = (event: any) => {
    this.setState({
      primaryDomain: event.target.value,
      primaryDomainError: '',
    });
  };

  onChangeEmpNumber = (event: any) => {
    this.setState({
      companySize: event.target.value,
    });
  };

  render() {
    let menuItems = _.map(sizeOfCompanyOptions.sizeOptions, (size) => {
      return { key: size.id, value: size.id, label: size.text };
    });

    return (
      <Dialog
        maxWidth={'md'}
        fullWidth={true}
        className={styles.dialogBox}
        open={this.props.open}
        onClose={this.onClose}
      >
        <div className={cx(styles.title, styles.titleFontSizePopupConf)}>
          Edit Company Details
          <IconButton
            color="inherit"
            onClick={this.onClose}
            className={cx(styles.closeIcon)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.companyInfoDescription}>
          You are now editing {_.get(this.props, 'company.name', '')} details.
        </div>
        <div className={styles.body}>
          <div className={styles.inputContainer}>
            {this.renderLoading()}
            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                fullWidth
                label="Company Name"
                variant="outlined"
                margin="normal"
                value={this.state.name}
                onChange={this.onChangeName}
                error={Boolean(this.state.nameError)}
                helperText={this.state.nameError}
                onClear={this.onDeleteName}
                disabled={this.props.isLoading}
                id="street-address-popup"
              />
            </div>

            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                fullWidth
                label="Company Legal Name"
                variant="outlined"
                margin="normal"
                value={this.state.legalName}
                onChange={this.onChangeLegalName}
                error={Boolean(this.state.legalNameError)}
                helperText={this.state.legalNameError}
                onClear={this.onDeleteLegalName}
                disabled={this.props.isLoading}
              />
            </div>

            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                fullWidth
                label="Domain"
                variant="outlined"
                margin="normal"
                value={this.state.primaryDomain}
                onChange={this.onChangePrimaryDomain}
                error={Boolean(this.state.primaryDomainError)}
                helperText={this.state.primaryDomainError}
                onClear={this.onDeletePrimaryDomain}
                disabled={this.props.isLoading}
              />
            </div>

            <div className={styles.inputWrapper}>
              <TextFieldWithClear
                fullWidth
                label="Company Code"
                variant="outlined"
                margin="normal"
                value={this.state.companyCode}
                onChange={this.onChangeCompanyCode}
                error={Boolean(this.state.codeError)}
                helperText={this.state.codeError}
                onClear={this.onDeleteCompanyCode}
                disabled={this.props.isLoading}
              />
            </div>

            <div className={styles.inputWrapper}>
              <CustomSelect
                margin={'normal'}
                variant={'outlined'}
                className={styles.selectWrapper}
                htmlFor={'input-type'}
                label={'Number of Employees'}
                value={this.state.companySize}
                onChange={this.onChangeEmpNumber}
                fullWidth={true}
                labelWidth={153}
                inputName={'user'}
                disabled={this.props.isLoading}
                MenuProps={{
                  style: { marginTop: 4, boxShadow: 'none' },
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                menuItems={menuItems}
                id="city-popup"
              />
            </div>
          </div>
          <div className={styles.updatePopup}>
            <div className={styles.billingAddressContainer}>
              <h2 className={styles.informationTitleCompany}>Address</h2>
            </div>
            <div
              className={cx(
                styles.inputContainer,
                styles.inputContainerCreditCard,
              )}
            >
              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  onChange={this.onChangeStreetAddress}
                  fullWidth
                  label="Street Address"
                  variant="outlined"
                  margin="normal"
                  value={this.state.streetAddress}
                  onClear={this.onDeleteStreetAddress}
                  disabled={this.props.isLoading}
                />
              </div>
              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  onChange={this.onChangeStreetAddress2}
                  fullWidth
                  label="Street Address 2"
                  variant="outlined"
                  margin="normal"
                  value={this.state.streetAddress2}
                  onClear={this.onDeleteStreetAddress2}
                  disabled={this.props.isLoading}
                />
              </div>
              <div className={styles.inputWrapper}>
                <TextFieldWithClear
                  onChange={this.onChangeCity}
                  fullWidth
                  label="City"
                  variant="outlined"
                  margin="normal"
                  value={this.state.city}
                  onClear={this.onDeleteCity}
                  disabled={this.props.isLoading}
                />
              </div>

              {this.renderRegion()}

              <div className={styles.inputWrapper}>
                <AutoSuggest
                  className={styles.dropdownContainer}
                  onChange={(value: any) => {
                    this.onChangeCountryAuto(value);
                  }}
                  label="Country"
                  value={this.state.country}
                  dataSet={countries}
                  disabled={this.props.isLoading}
                  id="country-popup"
                />
              </div>

              {this.renderZipCode()}
            </div>
          </div>
        </div>

        <div className={styles.popupButtonsContainer}>
          <Button
            className={styles.compPopupButton}
            variant="contained"
            color="primary"
            onClick={this.onSubmit}
            disabled={this.props.isLoading}
            id="submit-update"
          >
            Save
          </Button>
        </div>
      </Dialog>
    );
  }
}

export default CompanyDetailsPopup;
