exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".pages-ManageBlackBoxPermissions-styles___h1--2E7an {\n  text-align: left;\n}\n\n.pages-ManageBlackBoxPermissions-styles___selectWrapperPermissions--5sx6Y {\n  width: 100%;\n  min-width: 150px !important;\n}\n\n.pages-ManageBlackBoxPermissions-styles___permissionsBody--2FDns {\n  max-width: 1200px;\n  overflow-x: auto;\n  border-radius: 4px;\n}\n\n.pages-ManageBlackBoxPermissions-styles___agencyNames--2_f1T {\n  min-width: 200px;\n}\n", "", {"version":3,"sources":["/tmp/build_188bc313/src/pages/ManageBlackBoxPermissions/styles.pcss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;CAClB;;AAED;EACE,YAAY;EACZ,4BAA4B;CAC7B;;AAED;EACE,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;CACpB;;AAED;EACE,iBAAiB;CAClB","file":"styles.pcss","sourcesContent":[".h1 {\n  text-align: left;\n}\n\n.selectWrapperPermissions {\n  width: 100%;\n  min-width: 150px !important;\n}\n\n.permissionsBody {\n  max-width: 1200px;\n  overflow-x: auto;\n  border-radius: 4px;\n}\n\n.agencyNames {\n  min-width: 200px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"h1": "pages-ManageBlackBoxPermissions-styles___h1--2E7an",
	"selectWrapperPermissions": "pages-ManageBlackBoxPermissions-styles___selectWrapperPermissions--5sx6Y",
	"permissionsBody": "pages-ManageBlackBoxPermissions-styles___permissionsBody--2FDns",
	"agencyNames": "pages-ManageBlackBoxPermissions-styles___agencyNames--2_f1T"
};