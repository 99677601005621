exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".components-Lookup-RecordForm-styles___form--1tqgc {\n  display: flex;\n}\n\n  .components-Lookup-RecordForm-styles___form--1tqgc .components-Lookup-RecordForm-styles___inputWrapper--2Uk9W {\n    margin-right: 32px;\n    width: 160px;\n  }\n\n  .components-Lookup-RecordForm-styles___form--1tqgc .components-Lookup-RecordForm-styles___inputWrapper--2Uk9W.components-Lookup-RecordForm-styles___small--2sOl6 {\n      width: 160px;\n    }\n\n  .components-Lookup-RecordForm-styles___form--1tqgc .components-Lookup-RecordForm-styles___inputWrapper--2Uk9W:last-child {\n      margin-right: 0;\n    }\n\n  .components-Lookup-RecordForm-styles___form--1tqgc .components-Lookup-RecordForm-styles___inputWrapper--2Uk9W .components-Lookup-RecordForm-styles___button--3f3Nz {\n      margin-top: 16px;\n    }\n\n  .components-Lookup-RecordForm-styles___form--1tqgc .components-Lookup-RecordForm-styles___inputWrapper--2Uk9W .components-Lookup-RecordForm-styles___selectWrapper--3uMOR {\n      width: 100%;\n    }\n", "", {"version":3,"sources":["/tmp/build_188bc313/src/components/Lookup/RecordForm/styles.pcss"],"names":[],"mappings":"AAAA;EACE,cAAc;CACf;;EAEC;IACE,mBAAmB;IACnB,aAAa;GACd;;EAED;MACI,aAAa;KACd;;EAEH;MACI,gBAAgB;KACjB;;EAEH;MACI,iBAAiB;KAClB;;EAEH;MACI,YAAY;KACb","file":"styles.pcss","sourcesContent":[".form {\n  display: flex;\n}\n\n  .form .inputWrapper {\n    margin-right: 32px;\n    width: 160px;\n  }\n\n  .form .inputWrapper.small {\n      width: 160px;\n    }\n\n  .form .inputWrapper:last-child {\n      margin-right: 0;\n    }\n\n  .form .inputWrapper .button {\n      margin-top: 16px;\n    }\n\n  .form .inputWrapper .selectWrapper {\n      width: 100%;\n    }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"form": "components-Lookup-RecordForm-styles___form--1tqgc",
	"inputWrapper": "components-Lookup-RecordForm-styles___inputWrapper--2Uk9W",
	"small": "components-Lookup-RecordForm-styles___small--2sOl6",
	"button": "components-Lookup-RecordForm-styles___button--3f3Nz",
	"selectWrapper": "components-Lookup-RecordForm-styles___selectWrapper--3uMOR"
};