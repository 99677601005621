import React, { useState, useEffect } from 'react';
import { API_URL } from '../../configs/Api';
import { getHeaders } from '../../middleware/Api';
import { enqueueSnackbar } from 'actions/Status';
const styles = require('./styles.pcss');

interface SupportLoginButtonProps {
  userId: string;
}

const SupportLoginButton: React.FC<SupportLoginButtonProps> = ({ userId }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSupportLogin = async () => {
    setLoading(true);
    try {
      const response: any = await fetch(`${API_URL}user/${userId}/supportLogin`, {
        method: 'GET',
        headers: getHeaders(),
      });
      
      if (!response.ok) {
        enqueueSnackbar(response.payload, 'error');
        throw new Error(`Error: ${response.payload}`);
      }

      const data = await response.json();
      if (data.payload) {
        window.open(data.payload, '_blank');
      }
      setLoading(false);
    } catch (err: any) {
      enqueueSnackbar('API request failed', 'error');
      setLoading(false);
    }
  }

  return (
    <div className={styles.container}>
      {loading && <p>Loading...</p>}
      {!loading && (
        <button onClick={handleSupportLogin} className={styles.supportLoginButton}>
          Login
        </button>
      )}
    </div>
  );
};

export default SupportLoginButton;
