import React, { useState } from 'react';
const styles = require('./styles.pcss');
import _ from 'lodash-es';
import Dialog from '@material-ui/core/Dialog';
import cx from 'classnames';
import { useForm, SubmitHandler, Controller, get } from 'react-hook-form';
import {
  Button,
  MenuItem,
  Select,
  TextField,
  OutlinedInput,
} from '@material-ui/core';
import { AIRLINE_NAME_MAP } from '../../helpers/airlinemap';
import AutoSuggest from 'components/Inputs/AutoSuggest/AutoSuggest';
import SvgAirplane from '../../static/icons/svgAirplane';

enum StatusEnum {
  completed = 'completed',
  canceled = 'canceled',
  pending = 'pending',
  errored = 'errored',
}

type Inputs = {
  email: string;
  status: StatusEnum;
  segments?: any;
  confirmationNumber?: string;
};

type Props = {
  open: boolean;
  close: any;
  type: string;
  booking: any;
  id: any;
  confirmationNumber: string;
  status: string;
  identifier: any;
  additionalEmails: string;
  userEmail: string;
  updateBooking: Function;
  notes?: string;
};

export default function ModifyBookingsDetailsPopup(props: Props) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm();
  watch();

  let airlines: any = [];
  _.forOwn(AIRLINE_NAME_MAP, function (value, key) {
    airlines.push({ code: key, name: value });
  });

  const segments = props.booking?.segments?.reduce(
    (allSegments: any[], segmentGroup: any) => {
      allSegments.push(...segmentGroup.segment);
      return allSegments;
    },
    [],
  );

  const onClose = () => {
    reset();
    props.close();
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    data.segments = data.segments?.map((segment: any) => {
      const airline = airlines.find(
        (airline: any) => airline.name === segment.airline,
      );
      const {
        arrivalTime,
        arrivalDate,
        departureDate,
        departureTime,
        arrival: oldArrival,
        departure: oldDeparture,
      } = segment;

      const arrival = `${arrivalDate}T${arrivalTime}${oldArrival?.substring(16)}`;
      const departure = `${departureDate}T${departureTime}${oldDeparture?.substring(16)}`;

      return {
        ...segment,
        arrival,
        departure,
        airline: airline.code,
      };
    });
    props.updateBooking({ ...data, bookingId: props.id });
    props.close();
  };

  return (
    <Dialog
      maxWidth={'md'}
      fullWidth={true}
      className={cx(styles.dialogBox, styles.modifyBookingDetailsPopup)}
      open={props.open}
      onClose={close}
    >
      <div className={styles.contentContainer}>
        <h1 className={styles.headerText}>Modify Booking Details</h1>
        <h2 className={styles.subText}>
          Modify the booking details stored in the TravelBank system
        </h2>
        <form
          className={styles.fieldContainer}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            label="User Email"
            defaultValue={props.userEmail}
            variant="outlined"
            error={!!get(errors, 'email')?.message}
            helperText={get(errors, 'email')?.message}
            inputProps={{
              ...register('email', {
                required: 'This field is required',
                pattern: {
                  message: 'Please enter a valid email address',
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/,
                },
              }),
            }}
          />
          <TextField
            label="Confirmation Number"
            defaultValue={props.confirmationNumber}
            variant="outlined"
            error={!!get(errors, 'confirmationNumber')?.message}
            helperText={get(errors, 'confirmationNumber')?.message}
            inputProps={{
              ...register('confirmationNumber'),
            }}
          />

          <Controller
            name={'status'}
            control={control}
            defaultValue={props.status}
            render={({ field }) => (
              <Select
                {...field}
                input={
                  <OutlinedInput
                    labelWidth={0}
                    name={field.name}
                  />
                }
                variant="outlined"
              >
                <MenuItem
                  key="canceled"
                  value="canceled"
                >
                  Canceled
                </MenuItem>
                <MenuItem
                  key="pending"
                  value="pending"
                >
                  Pending
                </MenuItem>
                <MenuItem
                  key="errored"
                  value="errored"
                >
                  Errored
                </MenuItem>
                <MenuItem
                  key="completed"
                  value="completed"
                >
                  Completed
                </MenuItem>
              </Select>
            )}
          />
          {props.booking?.type === 'flight' &&
            segments.map((segment: any, index: number) => {
              return (
                <div
                  className={styles.segmentContainer}
                  key={`segment-fields-${index}`}
                >
                  <h2 className={styles.segmentHeader}>Segment {index + 1}</h2>
                  <div className={styles.segmentFieldContainer}>
                    <Controller
                      name={'segments.' + index + '.airline'}
                      control={control}
                      defaultValue={
                        AIRLINE_NAME_MAP[
                          (segment?.airline as keyof typeof AIRLINE_NAME_MAP) ||
                            (segment?.operatingAirline as keyof typeof AIRLINE_NAME_MAP)
                        ]
                      }
                      render={({ field }) => (
                        <AutoSuggest
                          {...field}
                          label={'Airline'}
                          className={styles.segmentAirlineContainer}
                          dataSet={airlines}
                          maxMenuHeight={90}
                        />
                      )}
                    />
                    <TextField
                      label="Flight Number"
                      defaultValue={segment.flightNumber}
                      variant="outlined"
                      error={
                        !!get(errors, 'segments.' + index + '.flightNumber')
                          ?.message
                      }
                      helperText={
                        get(errors, 'segments.' + index + '.flightNumber')
                          ?.message
                      }
                      inputProps={{
                        ...register('segments.' + index + '.flightNumber', {
                          required: 'This is required',
                        }),
                      }}
                    />
                    <TextField
                      label="Air Plane Name"
                      defaultValue={segment.planeName}
                      variant="outlined"
                      inputProps={{
                        ...register('segments.' + index + '.planeName'),
                      }}
                    />
                  </div>
                  <div className={styles.segmentFieldContainer}>
                    <TextField
                      label="From Airport Code"
                      defaultValue={segment.from}
                      variant="outlined"
                      error={
                        !!get(errors, 'segments.' + index + '.from')?.message
                      }
                      helperText={
                        get(errors, 'segments.' + index + '.from')?.message
                      }
                      inputProps={{
                        ...register('segments.' + index + '.from', {
                          required: 'This field is required',
                          pattern: {
                            message: 'Invalid 3 digit IATA code',
                            value: /^[a-zA-Z0-9]{3}$/,
                          },
                        }),
                      }}
                    />

                    <SvgAirplane
                      width={24}
                      height={20}
                    />
                    <TextField
                      label="To Airport Code"
                      defaultValue={segment.to}
                      variant="outlined"
                      error={
                        !!get(errors, 'segments.' + index + '.to')?.message
                      }
                      helperText={
                        get(errors, 'segments.' + index + '.to')?.message
                      }
                      inputProps={{
                        ...register('segments.' + index + '.to', {
                          required: 'This field is required',
                          pattern: {
                            message: 'Invalid 3 digit IATA code',
                            value: /^[a-zA-Z0-9]{3}$/,
                          },
                        }),
                      }}
                    />
                  </div>
                  <div className={styles.segmentFieldContainer}>
                    <TextField
                      label="Depart Time (hh:mm 24hr)"
                      defaultValue={segment.departure?.substring(11, 16)}
                      variant="outlined"
                      error={
                        !!get(errors, 'segments.' + index + '.departureTime')
                          ?.message
                      }
                      helperText={
                        get(errors, 'segments.' + index + '.departureTime')
                          ?.message
                      }
                      inputProps={{
                        ...register('segments.' + index + '.departureTime', {
                          required: 'This field is required',
                          pattern: {
                            value: /^(?:[01]\d|2[0-3]):[0-5]\d$/,
                            message: 'Invalid hh:mm time value',
                          },
                        }),
                      }}
                    />
                    <TextField
                      label="Arrival Time (hh:mm 24hr)"
                      defaultValue={segment.arrival?.substring(11, 16)}
                      variant="outlined"
                      error={
                        !!get(errors, 'segments.' + index + '.arrivalTime')
                          ?.message
                      }
                      helperText={
                        get(errors, 'segments.' + index + '.arrivalTime')
                          ?.message
                      }
                      inputProps={{
                        ...register('segments.' + index + '.arrivalTime', {
                          required: 'This field is required',
                          pattern: {
                            value: /^(?:[01]\d|2[0-3]):[0-5]\d$/,
                            message: 'Invalid hh:mm time value',
                          },
                        }),
                      }}
                    />
                  </div>
                  <div className={styles.segmentFieldContainer}>
                    <TextField
                      label="Depart Date (YYYY-MM-DD)"
                      placeholder="YYYY-MM-DD"
                      defaultValue={segment.departure?.substring(0, 10)}
                      variant="outlined"
                      error={
                        !!get(errors, 'segments.' + index + '.departureDate')
                          ?.message
                      }
                      helperText={
                        get(errors, 'segments.' + index + '.departureDate')
                          ?.message
                      }
                      inputProps={{
                        ...register('segments.' + index + '.departureDate', {
                          required: 'This field is required',
                          pattern: {
                            value:
                              /^(?:\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1]))$/,
                            message: 'Invalid YYYY-MM-DD value',
                          },
                        }),
                      }}
                    />
                    <TextField
                      label="Arrival Date (YYYY-MM-DD)"
                      placeholder="YYYY-MM-DD"
                      defaultValue={segment.arrival?.substring(0, 10)}
                      variant="outlined"
                      error={
                        !!get(errors, 'segments.' + index + '.arrivalDate')
                          ?.message
                      }
                      helperText={
                        get(errors, 'segments.' + index + '.arrivalDate')
                          ?.message
                      }
                      inputProps={{
                        ...register('segments.' + index + '.arrivalDate', {
                          required: 'This field is required',
                          pattern: {
                            value:
                              /^(?:\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1]))$/,
                            message: 'Invalid YYYY-MM-DD value',
                          },
                        }),
                      }}
                    />
                  </div>
                  <input
                    {...register('segments.' + index + '.departure', {
                      value: segment.departure,
                    })}
                    type="hidden"
                  />
                  <input
                    {...register('segments.' + index + '.arrival', {
                      value: segment.arrival,
                    })}
                    type="hidden"
                  />
                </div>
              );
            })}

          <div className={styles.actionButtonContainer}>
            <Button
              variant="outlined"
              onClick={() => onClose()}
            >
              Cancel Updates
            </Button>
            <Button
              className={styles.button}
              variant="contained"
              color="primary"
              type="submit"
            >
              Update
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
}
