import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import FeaturesTableRow from './FeaturesTableRow';
import Loader from 'components/Loader/Loader';
const styles = require('./styles.pcss');
import { hasPermission } from '../../helpers/Permission';
import { Features, ReduxState } from '../../reducers/types';
import { TB_BLACKBOX_ENV } from '../../configs/Api';

type Props = {
  features: Array<Features>;
  organizationId?: any;
  agencyId?: string;
  userId?: string;
  isLoading: boolean;
  type: string;
  onHandleChange: Function;
};

const BetaFeatures = (props: Props) => {
  let updatePermission =
    !hasPermission('organizations', 'update') ||
    !hasPermission('features', 'update');

  if (props.agencyId || props.userId) {
    updatePermission = !hasPermission('features', 'update');
  }

  return (
    <Paper
      className={
        props.isLoading ? cx(styles.paper, styles.loading) : styles.paper
      }
    >
      <div className={styles.expensesFeatures}>
        <h2>Beta Features</h2>
        {props.isLoading && (
          <Paper className={styles.pageWrapper}>
            <div>
              <Loader visible={props.isLoading} />
            </div>
          </Paper>
        )}
        <Table>
          <TableBody>
            {props.features
              .filter((feature) => feature.scope.type === props.type)
              .map((feature: any, i: number) => {
                let enabled = false;

                if (
                  props.organizationId &&
                  _.indexOf(
                    feature.scope?.organizations,
                    props.organizationId,
                  ) > -1
                ) {
                  enabled = true;
                } else if (
                  props.agencyId &&
                  _.indexOf(feature.scope?.agencies, props.agencyId) > -1
                ) {
                  enabled = true;
                } else if (
                  props.userId &&
                  _.indexOf(feature.scope?.users, props.userId) > -1
                ) {
                  enabled = true;
                }

                feature.labelName =
                  _.get(feature, 'friendlyName', false) != false
                    ? feature.friendlyName
                    : feature.name;

                if (TB_BLACKBOX_ENV !== 'production') {
                  feature.labelName = `${feature.labelName} (${feature.confidence})`;
                }

                return (
                  <FeaturesTableRow
                    key={i}
                    rowColumns={feature}
                    enabled={enabled}
                    updateOrganizationPermission={updatePermission}
                    handleclick={(event: any) => {
                      props.onHandleChange(feature, event.target.checked);
                    }}
                  />
                );
              })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

function mapStateToProps(state: ReduxState) {
  return {
    isLoading: state.betaFeatures.isLoading,
  };
}

export default connect(mapStateToProps, '')(BetaFeatures);
