exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".components-ManageBlackBox-Users-styles___bodyPaper--1Bj5H {\n  width: 100%;\n}\n\n.components-ManageBlackBox-Users-styles___notFound--2SKe5 {\n  text-align: center;\n  margin: 20px;\n  font-size: 18px;\n  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;\n}\n\n.components-ManageBlackBox-Users-styles___bbusersTable--1Knyj tr:last-child td {\n    border-bottom: none !important;\n  }\n", "", {"version":3,"sources":["/tmp/build_188bc313/src/components/ManageBlackBox/Users/styles.pcss"],"names":[],"mappings":"AAAA;EACE,YAAY;CACb;;AAED;EACE,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,wDAAwD;CACzD;;AAED;IACI,+BAA+B;GAChC","file":"styles.pcss","sourcesContent":[".bodyPaper {\n  width: 100%;\n}\n\n.notFound {\n  text-align: center;\n  margin: 20px;\n  font-size: 18px;\n  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;\n}\n\n.bbusersTable tr:last-child td {\n    border-bottom: none !important;\n  }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"bodyPaper": "components-ManageBlackBox-Users-styles___bodyPaper--1Bj5H",
	"notFound": "components-ManageBlackBox-Users-styles___notFound--2SKe5",
	"bbusersTable": "components-ManageBlackBox-Users-styles___bbusersTable--1Knyj"
};