import * as React from 'react';
import _ from 'lodash-es';
import Paper from '@material-ui/core/Paper';
import TransactionTableHead from './TableHead';
import TransactionTableRow from './TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import RefundConfirmation from 'components/Popup/RefundConfirmation';
import RenderIcon from 'components/CreditCard/RenderIcon';
import SvgIconBrex from '../../../static/icons/svgIconBrex';
import { cardType } from '../../../helpers/creditCardType';

const styles = require('./styles.pcss');

type Props = {
  transactions: Array<any>;
  refundable: Boolean;
  onClickRefund: Function;
  organization: any;
  bookingPnr: any;
};

type State = {
  openRefundBookingPopup: boolean;
  cardLast4: any;
  transactionId: string;
  bodyText: string;
  amount: any;
  errorAmount: any;
  displayMoneyIcon: any;
};

class Transactions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openRefundBookingPopup: false,
      cardLast4: '',
      transactionId: '',
      bodyText: '',
      amount: '',
      errorAmount: '',
      displayMoneyIcon: false,
    };
  }

  onOpenRefundBookingPopup = () => {
    this.setState({
      openRefundBookingPopup: true,
    });
  };

  closeRefundPopup = () => {
    this.setState({
      openRefundBookingPopup: false,
      amount: '',
      errorAmount: '',
      transactionId: '',
    });
  };

  onChangeAmount = (event: any) => {
    this.setState({
      amount: event.target.value,
      errorAmount: '',
    });
  };

  onFocusAmount = (event: any) => {
    if (!this.state.displayMoneyIcon) {
      this.setState({
        displayMoneyIcon: true,
      });
    }
  };

  onBlurAmount = (event: any) => {
    if (this.state.amount.trim().length === 0) {
      this.setState({
        displayMoneyIcon: false,
      });
    }
  };

  onClearAmount = () => {
    this.setState({
      amount: '',
      errorAmount: '',
      displayMoneyIcon: false,
    });
  };

  isValidAmount = () => {
    const regex = RegExp('^[0-9]+([,.][0-9]*)?$');

    if (!this.state.amount) {
      return false;
    }

    if (!regex.test(this.state.amount)) {
      return false;
    }

    if (this.state.amount <= 0) {
      return false;
    }

    const selectedTransaction = _.find(
      this.props.transactions,
      (t) => t.id == this.state.transactionId,
    );

    if (
      parseFloat(this.state.amount) >
      Math.abs(selectedTransaction.amount) / 100
    ) {
      return false;
    }

    const relatedRefundedTransactions = this.props.transactions
      .filter(
        (t) =>
          t.relatedTransactionId == this.state.transactionId &&
          t.purpose == 'refund',
      )
      .map((t) => t.amount);

    let refundedAmount = _.sum(relatedRefundedTransactions) * -1;

    if (
      Math.round(refundedAmount + this.state.amount * 100) >
      _.get(selectedTransaction, 'amount', 0)
    ) {
      return false;
    }

    return true;
  };

  onSubmitRefundPopup = () => {
    if (this.isValidAmount()) {
      this.props.onClickRefund(
        this.state.transactionId,
        parseFloat(this.state.amount),
      );

      this.setState({
        openRefundBookingPopup: false,
        transactionId: '',
        amount: '',
        errorAmount: '',
      });
    } else {
      this.setState({
        errorAmount: 'Invalid value',
      });
    }
  };

  isCorporate = (card: any) => {
    if (
      (_.get(card, 'organizationId', null) !== null &&
        _.get(card, 'organizationId', '') !== '') ||
      (_.isArray(_.get(card, 'departmentIds', false)) &&
        _.get(card, 'departmentIds', []).length > 0)
    ) {
      return true;
    }

    return false;
  };

  onClickRefund = (transaction: any) => {
    let bodyText = '';

    if (transaction.type === 'brexPoints') {
      bodyText = `Brex Points`;
    } else if (_.get(transaction, 'creditCard', false)) {
      bodyText = `${_.capitalize(
        _.get(cardType, _.get(transaction, 'creditCard.type', '')),
      )}... ${_.get(transaction, 'creditCard.last4', '')}`;
    } else if (_.get(transaction, 'data.payment_method_details.card', false)) {
      bodyText = `${_.capitalize(
        _.get(transaction, 'data.payment_method_details.card.brand', ''),
      )}... ${_.get(
        transaction,
        'data.payment_method_details.card.last4',
        '',
      )}`;
    }

    this.setState({
      bodyText,
      cardLast4: _.get(
        transaction,
        'data.payment_method_details.card.last4',
        '',
      ),
      openRefundBookingPopup: true,
      transactionId: _.get(transaction, 'id', ''),
    });
  };

  _isRefundable = (transaction: any) => {
    if (!this.props.refundable) {
      return false;
    }

    if (_.toLower(transaction.merchant) != 'travelbank') {
      return false;
    }

    if (transaction.purpose == 'refund') {
      return false;
    }

    const relatedRefundedTransactions = this.props.transactions
      .filter(
        (t) =>
          t.relatedTransactionId == _.get(transaction, 'id', null) &&
          t.purpose == 'refund',
      )
      .map((t) => t.amount);

    let refundedAmount = _.sum(relatedRefundedTransactions) * -1;

    if (refundedAmount >= _.get(transaction, 'amount', 0)) {
      return false;
    }

    return true;
  };

  renderTableRow = (transaction: any, i: number) => {
    if (transaction.purpose === 'refund') {
      const relatedTransaction: any = _.find(
        this.props.transactions,
        (t) => t.id == _.get(transaction, 'relatedTransactionId', null),
      );
      _.set(transaction, 'data', _.get(relatedTransaction, 'data', ''));
      _.set(transaction, 'type', _.get(relatedTransaction, 'type', ''));
    }

    return (
      <TransactionTableRow
        key={transaction.id}
        transaction={transaction}
        onClickRefund={this.onClickRefund.bind(this, transaction)}
        showRefund={this._isRefundable(transaction)}
      />
    );
  };

  render() {
    let icon = (
      <SvgIconBrex
        width={18}
        height={18}
      />
    );
    let label = 'Points';
    const selecterTransaction = _.find(
      this.props.transactions,
      (t) => t.id == this.state.transactionId,
    );

    if (_.get(selecterTransaction, 'type', '') != 'brexPoints') {
      icon = (
        <RenderIcon
          type={
            _.get(selecterTransaction, 'data.source.brand', false) ||
            _.get(selecterTransaction, 'creditCard.typeFull', '')
          }
          width={18}
          height={18}
        />
      );
      label = 'Amount';
    }

    return (
      <>
        <Paper>
          <Table>
            <TransactionTableHead />
            <TableBody>
              {this.props.transactions.map(this.renderTableRow)}
            </TableBody>
          </Table>
        </Paper>

        <RefundConfirmation
          open={this.state.openRefundBookingPopup}
          onClose={this.closeRefundPopup}
          onSubmit={this.onSubmitRefundPopup}
          bookingNumber={this.props.bookingPnr}
          body={this.state.bodyText}
          icon={icon}
          onChangeAmount={this.onChangeAmount}
          errorMessage={this.state.errorAmount}
          label={label}
          clearAmount={this.onClearAmount}
          amount={this.state.amount}
          isCorporate={
            this.isCorporate(
              _.get(
                selecterTransaction,
                'data.payment_method_details.card',
                false,
              ),
            ) ||
            this.isCorporate(_.get(selecterTransaction, 'creditCard', false))
          }
          onFocusAmount={this.onFocusAmount}
          onBlurAmount={this.onBlurAmount}
          displayMoneyIcon={
            _.get(selecterTransaction, 'type', '') == 'brexPoints'
              ? false
              : this.state.displayMoneyIcon
          }
        />
      </>
    );
  }
}

export default Transactions;
