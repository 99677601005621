import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TravelPolicyTableRows from './TravelPolicyTableRows';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Loader from 'components/Loader/Loader';
import { BudgetPolicy } from './travelPolicy.interface';
const styles = require('./styles.pcss');

type Props = {
  policyRules?: any;
  budgetPolicy?: BudgetPolicy;
  isLoading: boolean;
  isUpdatedTravelPolicyEnabled: boolean;
};

const budgetClass: any = {
  name: 'Class',
  description: 'Fare Class or Hotel Class that the budget will be based on.',
};

const comfort: any = {
  name: 'Comfort',
  description:
    'Number of stops or distance from search that the budget will be based on.',
};

class BudgetRules extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  onFormatLabels({
    hotelRating,
    flightComfort,
    hotelDistance,
  }: {
    hotelRating: number | string | undefined;
    flightComfort: string | boolean | undefined;
    hotelDistance: number | undefined;
  }) {
    const isFlightComfortBool = typeof flightComfort == 'boolean';

    return {
      hotelRating: hotelRating ? hotelRating + ' Star' : '-',
      flightComfort:
        isFlightComfortBool && flightComfort
          ? 'Nonstop'
          : isFlightComfortBool && !flightComfort
            ? '1+ Stops'
            : '-',
      hotelDistance: hotelDistance ? hotelDistance + ' miles' : '-',
    };
  }

  render() {
    const {
      policyRules,
      isLoading,
      isUpdatedTravelPolicyEnabled,
      budgetPolicy,
    } = this.props;

    let flightClass, hotelRating, flightComfort, hotelDistance;
    if (!isUpdatedTravelPolicyEnabled) {
      flightClass = _.get(policyRules, '[0].airClass', '-');

      ({ hotelRating, hotelDistance, flightComfort } = this.onFormatLabels({
        hotelRating: _.get(policyRules, '[0].hotelRating', ''),
        flightComfort: _.get(policyRules, '[0].airNonStop', '-'),
        hotelDistance: _.get(policyRules, '[0].hotelDistance', ''),
      }));
    }

    if (isUpdatedTravelPolicyEnabled) {
      flightClass = budgetPolicy?.airClass;

      ({ hotelRating, hotelDistance, flightComfort } = this.onFormatLabels({
        hotelRating: budgetPolicy?.hotelRating,
        flightComfort: budgetPolicy?.airNonStop,
        hotelDistance: budgetPolicy?.hotelDistance,
      }));
    }

    return (
      <Paper
        className={isLoading ? cx(styles.paper, styles.loading) : styles.paper}
      >
        <div className={styles.policyRules}>
          <h2>Budget</h2>
          <div>
            <Loader visible={isLoading} />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="right"
                  className={cx(styles.tableCell, styles.header)}
                ></TableCell>
                <TableCell
                  align="center"
                  className={cx(styles.tableCell, styles.header)}
                >
                  Flight
                </TableCell>
                <TableCell
                  align="center"
                  className={cx(styles.tableCell, styles.header)}
                >
                  Lodging
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TravelPolicyTableRows
                key={1}
                text={budgetClass}
                flight={flightClass}
                hotel={hotelRating}
                description={true}
              />
              <TravelPolicyTableRows
                key={2}
                text={comfort}
                flight={flightComfort}
                hotel={hotelDistance}
                description={true}
              />
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

export default BudgetRules;
