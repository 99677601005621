import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
const styles = require('./styles.pcss');

export default function DuffelSync(props: any) {
  const [orderId, setOrderId] = useState('');
  const onChange = (orderIdText: string) => {
    setOrderId(orderIdText);
  };

  const onClear = () => {
    setOrderId('');
  };
  return (
    <div>
      <h2 className={styles.syncHeader}>Sync Duffel Booking </h2>
      <div className={styles.syncContainer}>
        <TextFieldWithClear
          onChange={(event: any) => onChange(event.target.value)}
          label="Duffel Order Id"
          className={styles.orderIdField}
          variant="outlined"
          margin="normal"
          value={orderId}
          onClear={() => onClear()}
          fullWidth={true}
        />
        <Button
          color="primary"
          variant="outlined"
          className={styles.syncButton}
          onClick={() => props.sync(props.userId, orderId)}
          disabled={props.disabled}
        >
          Sync
        </Button>
      </div>
    </div>
  );
}
