exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".components-CreateUpdateCarbonEmissionProduct-styles___paper--jWvmI {\n  padding: 32px;\n  max-width: 736px;\n  margin: 0 0 30px;\n  display: flex;\n  flex-direction: column;\n}\n\n.components-CreateUpdateCarbonEmissionProduct-styles___selectWrapper--3CZge {\n  margin-right: 32px;\n}\n\n.components-CreateUpdateCarbonEmissionProduct-styles___button--3cCOZ {\n  width: 200px;\n}\n", "", {"version":3,"sources":["/tmp/build_c0b3ce23/src/components/CreateUpdateCarbonEmissionProduct/styles.pcss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;EACd,uBAAuB;CACxB;;AAED;EACE,mBAAmB;CACpB;;AAED;EACE,aAAa;CACd","file":"styles.pcss","sourcesContent":[".paper {\n  padding: 32px;\n  max-width: 736px;\n  margin: 0 0 30px;\n  display: flex;\n  flex-direction: column;\n}\n\n.selectWrapper {\n  margin-right: 32px;\n}\n\n.button {\n  width: 200px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"paper": "components-CreateUpdateCarbonEmissionProduct-styles___paper--jWvmI",
	"selectWrapper": "components-CreateUpdateCarbonEmissionProduct-styles___selectWrapper--3CZge",
	"button": "components-CreateUpdateCarbonEmissionProduct-styles___button--3cCOZ"
};