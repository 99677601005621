import {
  ACTION_GET_BOOKING,
  ACTION_GET_BOOKING_SUCCESS,
  ACTION_GET_BOOKING_FAILURE,
  ACTION_EMPTY_BOOKING,
  ACTION_POST_BOOKING_NEW_PAYMENT_SUCCESS,
  ACTION_POST_REFUND_BOOKING_SUCCESS,
  ACTION_PUT_BOOKINGS_CUSTOM_TAG,
  ACTION_PUT_BOOKINGS_CUSTOM_TAG_SUCCESS,
  ACTION_PUT_BOOKINGS_CUSTOM_TAG_FAILURE,
  ACTION_PUT_CANCEL_BOOKING_SUCCESS,
  ACTION_PUT_UPDATE_BOOKING_SUCCESS,
  ACTION_POST_BOOKING_FLIGHTCREDITS_SUCCESS,
  ACTION_POST_BOOKING_FLIGHTCREDITS,
} from '../configs/ActionTypes';

import _ from 'lodash-es';

const initialState = {
  booking: null,
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_BOOKING:
    case ACTION_PUT_BOOKINGS_CUSTOM_TAG: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_GET_BOOKING_SUCCESS: {
      const b = action.payload.payload;

      let booking: any = b;

      return {
        ...state,
        isLoading: false,
        booking: booking,
        success: true,
      };
    }

    case ACTION_POST_BOOKING_NEW_PAYMENT_SUCCESS:
    case ACTION_POST_REFUND_BOOKING_SUCCESS: {
      let booking = state.booking as any;
      _.set(
        booking,
        'transactions',
        _.get(action.payload.payload, 'transactions', []),
      );

      return {
        ...state,
        booking: booking,
      };
    }

    case ACTION_PUT_BOOKINGS_CUSTOM_TAG_SUCCESS: {
      let booking: any = _.clone(state.booking);
      const tagsId = action.payload.payload.tags;
      let tags = _.get(booking, 'possibleTags', []).filter((obj: any) =>
        tagsId.includes(obj.id),
      );

      booking.tags = tags;

      return {
        ...state,
        booking,
        isLoading: false,
        success: true,
      };
    }

    case ACTION_GET_BOOKING_FAILURE:
    case ACTION_PUT_BOOKINGS_CUSTOM_TAG_FAILURE: {
      const payload = action.payload.payload;

      return {
        ...state,
        success: false,
        isLoading: false,
      };
    }

    case ACTION_EMPTY_BOOKING: {
      return initialState;
    }

    case ACTION_PUT_CANCEL_BOOKING_SUCCESS: {
      let booking: any = state.booking;
      _.set(booking, 'status', 'canceled');

      return {
        ...state,
        booking,
      };
    }
    case ACTION_POST_BOOKING_FLIGHTCREDITS: {
      return {
        ...state,
        attemptedFlightCreditAdd: true,
      };
    }

    case ACTION_POST_BOOKING_FLIGHTCREDITS_SUCCESS: {
      const ticketNumber: string = action.payload.payload.ticketNumber;
      const booking: any = state.booking;
      _.set(booking, 'flightCreditRecord.ticketNumber', ticketNumber);
      return {
        ...state,
        booking,
      };
    }

    case ACTION_PUT_UPDATE_BOOKING_SUCCESS: {
      const payload = action.payload.payload;
      let booking: any = state.booking;

      _.set(booking, 'confirmationNumber', payload.confirmationNumber);
      _.set(booking, 'status', payload.status);
      _.set(booking, 'notes', payload.notes);

      if (payload.type === 'flight' && payload.segments) {
        let currentSegmentCounter = 0;
        const newSegments = booking.segments.map((segmentGroup: any) => {
          const segment = segmentGroup.segment.map((singleSegment: any) => {
            currentSegmentCounter++;
            return { ...payload.segments[currentSegmentCounter - 1] };
          });
          return {
            ...segmentGroup,
            from: segment?.[0]?.from,
            to: segment?.[segment.length - 1]?.to,
            segment,
          };
        });

        _.set(booking, 'segments', newSegments);
      }

      return {
        ...state,
        booking,
      };
    }

    default: {
      return state;
    }
  }
}
