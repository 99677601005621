import React, { useState } from 'react';
import _ from 'lodash-es';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import SvgCompletedAvatar from '../../static/icons/svgCompletedAvatar';
import Passenger from './Passenger';
const styles = require('./styles.pcss');
import { numberToEnglish } from '../../helpers/numberToEnglish';
import TbButton from 'components/TbButton/TbButton';
import EditBookingTravelersModal from 'components/EditBookingTravelersModal/EditBookingTravelersModal';

type Props = {
  passengers: any;
  traveler: any;
  isLoading?: any;
  type?: string;
  userTravelers: any;
  updateBookingTravelers: Function;
  bookingType: string;
  bookingId: string;
};

const renderAvatar = (props: Props) => {
  if (props.isLoading) {
    return (
      <Avatar
        alt="Avatar"
        className={styles.avatar}
      ></Avatar>
    );
  }

  if (
    _.get(props.traveler, 'avatar', '').length > 1 &&
    _.get(props.traveler, 'avatar', '') != null
  ) {
    return (
      <Avatar
        alt="Avatar"
        src={_.get(props.traveler, 'avatar', '')}
        className={styles.avatar}
      />
    );
  } else {
    let firstName =
      _.get(props, 'traveler.firstName', null) !== null
        ? _.get(props, 'traveler.firstName', null)
        : '';
    let lastName =
      _.get(props, 'traveler.lastName', null) !== null
        ? _.get(props, 'traveler.lastName', null)
        : '';
    let name = `${firstName} ${lastName}`;

    if (name.trim() === '') {
      return (
        <SvgCompletedAvatar
          width={32}
          height={32}
        />
      );
    }

    return (
      <Avatar
        alt="avatar"
        className={styles.avatarLetter}
      >
        {firstName.charAt(0)}
        {lastName.charAt(0)}
      </Avatar>
    );
  }
};

const Travelers = (props: Props) => {
  const [userTraveler, setUserTraveler] = useState(props.passengers[0]?.id);
  const [showEditTraveler, setShowEditTraveler] = useState(false);
  let firstName =
    _.get(props, 'passengers[0].firstName', null) !== null
      ? _.get(props, 'passengers[0].firstName', null)
      : '';
  let lastName =
    _.get(props, 'passengers[0].lastName', null) !== null
      ? _.get(props, 'passengers[0].lastName', null)
      : '';
  let name = `${firstName} ${lastName}`;

  let titleStyle =
    props.passengers.length > 1
      ? styles.travelerInformationFlightTitle
      : styles.lightSubtitle;
  let subtitleStyle =
    props.passengers.length > 1
      ? styles.travelerInformationFlightSubtitle
      : styles.boldText;

  if (props.type === 'hotel') {
    titleStyle = styles.travelerInformationHotelTitle;
    subtitleStyle = styles.travelerInformationHotelSubtitle;
  }

  const saveTravelers = (travelerIds: string[]) => {
    props.updateBookingTravelers(travelerIds);
    toggleShowEditTravelers();
  };

  const toggleShowEditTravelers = () => {
    setShowEditTraveler(!showEditTraveler);
  };

  return (
    <Paper className={styles.bookingDetails}>
      <div className={styles.header}>
        <div className={styles.avatarAndName}>
          <div className={styles.avatarContainer}>{renderAvatar(props)}</div>
          <div className={styles.headerInfoContainer}>
            <div>
              <h1 className={titleStyle}>Traveler Information</h1>
              <div className={subtitleStyle}>
                {props.passengers.length > 1 ? (
                  <>Information for all travelers</>
                ) : (
                  <span className={styles.boldText}>{name}</span>
                )}
              </div>
            </div>
            <TbButton
              onClick={toggleShowEditTravelers}
              text="Change Travelers"
            />
            <EditBookingTravelersModal
              closeModal={toggleShowEditTravelers}
              userTravelers={props.userTravelers}
              saveTravelers={saveTravelers}
              isOpen={showEditTraveler}
              currentBookingTravelers={props.passengers}
            />
          </div>
        </div>
      </div>
      {_.get(props, 'passengers', []).map((passenger: any, i: any) => {
        let numb = numberToEnglish(i + 1);

        return (
          <Passenger
            type={props.type}
            passengers={passenger}
            numb={numb}
            multi={props.passengers.length > 1}
          />
        );
      })}
    </Paper>
  );
};

export default Travelers;
