import * as React from 'react';
import * as _ from 'lodash-es';
import moment from 'moment';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import RenderIcon from 'components/CreditCard/RenderIcon';
import SvgIconBrex from '../../../static/icons/svgIconBrex';
import SvgIconRewards from '../../../static/icons/svgIconRewards';
import { cardType } from '../../../helpers/creditCardType';
import {
  TRANSACTION_TYPES,
  TRANSACTION_PURPOSE_TYPES,
  TRANSACTION_AMOUNT_TYPES,
} from '../../../constants';

const styles = require('./styles.pcss');

type Props = {
  transaction: any;
  key: any;
  onClickRefund: any;
  showRefund: any;
};

const TransactionTableRow = ({
  transaction,
  key,
  onClickRefund,
  showRefund,
}: Props) => {
  const {
    created,
    purpose,
    type,
    amountType,
    amount = 0,
    status = 'completed',
    merchant = '',
    description = '',
    creditCard: { type: creditCardType = '', last4: creditCardLast4 = '' } = {},
    data: {
      source: { brand: sourceBrand = false } = {},
      description: dataDescription = '',
      payment_method_details: {
        card: { brand: cardBrand = '', last4: paymentMethodLast4 = '' } = {},
      } = {},
    } = {},
  } = transaction || {};

  const _onRenderFormOfPayment = () => {
    if (purpose == TRANSACTION_PURPOSE_TYPES.savings) {
      return 'Travelbank Rewards';
    }

    if (type === TRANSACTION_TYPES.brexPoints) {
      return 'Brex Points';
    }

    if (type === TRANSACTION_TYPES.nitraPoints) {
      return 'Nitra Points';
    }

    if (cardBrand || paymentMethodLast4) {
      return `${_.capitalize(cardBrand)}... ${paymentMethodLast4}`;
    }

    if (creditCardType && creditCardLast4) {
      return `${_.capitalize(
        _.get(cardType, creditCardType),
      )}... ${creditCardLast4}`;
    }

    return '';
  };

  const _onRenderAmountString = () => {
    if (!amount) {
      return ' - - ';
    }
    if (amountType === 'USD') {
      const dividedAmount = (Math.abs(amount) / 100).toFixed(2);
      const prefix = purpose === TRANSACTION_PURPOSE_TYPES.refund ? '-$' : '$';
      return prefix + dividedAmount;
    }

    const isPointsTransaction = [
      TRANSACTION_AMOUNT_TYPES.points,
      TRANSACTION_AMOUNT_TYPES.brexPoints,
      TRANSACTION_AMOUNT_TYPES.nitraPoints,
    ].includes(amountType);

    if (isPointsTransaction) {
      const pointAmount = (amount / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return pointAmount + ' points';
    }

    return '';
  };

  const _onRenderAmountIcon = () => {
    if (
      [
        TRANSACTION_AMOUNT_TYPES.points,
        TRANSACTION_AMOUNT_TYPES.brexPoints,
      ].includes(amountType)
    ) {
      const SvgIcon =
        amountType === TRANSACTION_AMOUNT_TYPES.points
          ? SvgIconRewards
          : SvgIconBrex;

      return <SvgIcon width={18} height={18} />;
    }

    return (
      <RenderIcon type={sourceBrand || creditCardType} width={18} height={18} />
    );
  };

  return (
    <TableRow className={styles.tableRow} key={key}>
      <TableCell className={styles.tableRowCell}>
        {moment(created).format('MM/DD/YYYY')}
      </TableCell>
      <TableCell
        className={classNames(styles.tableRowCell, styles.description)}
      >
        {dataDescription || description}
      </TableCell>
      <TableCell className={styles.tableRowCell}>{merchant}</TableCell>
      <TableCell className={classNames(styles.tableRowCell)}>
        <div className={styles.tableRowCellWithIcon}>
          {_onRenderAmountIcon()}
          {_onRenderFormOfPayment()}
        </div>
      </TableCell>
      <TableCell className={styles.tableRowCell}>
        {
          <div className={styles.amountContainer}>
            {_onRenderAmountString()}
          </div>
        }
      </TableCell>
      <TableCell className={styles.tableRowCell}>
        <div className={classNames(styles.status, styles[status])}>
          {_.capitalize(status)}
        </div>
      </TableCell>
      <TableCell className={styles.tableRowCell}>
        {showRefund && (
          <Button className={styles.refundButton} onClick={onClickRefund}>
            Refund
          </Button>
        )}
      </TableCell>
      <TableCell className={styles.tableRowCell}>
        {transaction.blackboxUserName || 'app'}
      </TableCell>
    </TableRow>
  );
};

export default TransactionTableRow;
