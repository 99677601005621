import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import TextFieldWithClear from 'components/TextFieldWithIcon/TextFieldWithIcon';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const styles = require('../UserDetails/styles.pcss');

type Props = {
  pnr?: string;
  totalPrice?: any;
  userId: any;
  onSubmit: Function;
  displayMoneyIcon?: boolean;
  disabled: boolean;
};

export default function BookTrain(props: Props) {
  const [pnr, setPnr] = useState(props.pnr || '');
  const [pnrError, setPnrError] = useState('');
  const [totalPrice, setTotalPrice] = useState(props.totalPrice || '');
  const [totalPriceError, setTotalPriceError] = useState('');
  const [fieldsNotFilledError, setFieldsNotFilledError] = useState('');
  const [displayMoneyIcon, setDisplayMoneyIcon] = useState(
    props.displayMoneyIcon || false,
  );

  const onChangePnr = (event: any) => {
    setFieldsNotFilledError('');
    setPnrError('');
    setPnr(event.target.value);
  };

  const fieldsFilled = () => {
    return totalPriceError == '' && pnrError == '';
  };

  const onChangeTotalPrice = (event: any) => {
    setFieldsNotFilledError('');
    setTotalPriceError('');
    setTotalPrice(event.target.value);
  };

  const handleSubmit = () => {
    if (fieldsFilled()) {
      props.onSubmit({
        pnr: pnr,
        userId: props.userId,
        totalPrice: totalPrice,
      });
    } else {
      setFieldsNotFilledError(
        'Please ensure all of the information above is filled out correctly.',
      );
    }
  };

  const onClearPnr = () => {
    setPnr('');
    setPnrError('');
  };

  const onBlurPnr = () => {
    if (!pnr) {
      setPnrError('Required');
    }
  };

  const onClearTotalPrice = () => {
    setTotalPrice('');
    setTotalPriceError('');
    setDisplayMoneyIcon(false);
  };

  const onBlurTotalPrice = () => {
    const regex = RegExp('^[0-9]+([,.][0-9]*)?$');
    totalPrice.replace(',', '.');
    if (!totalPrice) {
      setTotalPriceError('Required');
      setDisplayMoneyIcon(false);
    } else if (
      !regex.test(totalPrice) ||
      isNaN(Number(totalPrice)) ||
      totalPrice == 0
    ) {
      setTotalPriceError('Please enter a valid amount');
    }
  };

  const onFocusTotalPrice = (event: any) => {
    if (!displayMoneyIcon) {
      setDisplayMoneyIcon(true);
    }
  };

  return (
    <div>
      <h2 className={styles.syncHeader}>Amtrak Booking </h2>
      <div className={styles.syncContainer}>
        <TextFieldWithClear
          onChange={onChangePnr}
          fullWidth
          label="PNR"
          variant="outlined"
          margin="normal"
          value={pnr}
          id={'pnr'}
          onBlur={onBlurPnr}
          onClear={onClearPnr}
          className={styles.orderIdField}
          helperText={pnrError}
          error={!!pnrError}
        />

        <TextFieldWithClear
          onChange={onChangeTotalPrice}
          fullWidth
          label="Amount"
          variant="outlined"
          margin="normal"
          value={totalPrice}
          id={'totalPrice'}
          onBlur={onBlurTotalPrice}
          onClear={onClearTotalPrice}
          className={styles.orderIdField}
          helperText={totalPriceError}
          error={!!totalPriceError}
          onFocus={onFocusTotalPrice}
          startAdornment={displayMoneyIcon}
          icon={<AttachMoneyIcon fontSize={'small'} />}
        />

        <Button
          className={styles.syncButton}
          variant="outlined"
          color="primary"
          onClick={handleSubmit}
          disabled={props.disabled}
        >
          Submit
        </Button>
      </div>
      <p className={styles.fcFieldsNotFilledError}>{fieldsNotFilledError}</p>
    </div>
  );
}
