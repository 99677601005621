import {
  ACTION_GET_COMPANIES,
  ACTION_GET_COMPANIES_SUCCESS,
  ACTION_GET_COMPANIES_FAILURE,
  ACTION_EMPTY_COMPANIES,
  ACTION_POST_COMPANY_SUCCESS,
} from '../configs/ActionTypes';

import { QUERY_LIMIT } from '../configs/General';

import { Company } from './types';

import _ from 'lodash-es';

const initialState = {
  companies: [],
  isLoading: false,
  loadedAll: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_COMPANIES: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_POST_COMPANY_SUCCESS: {
      let companies: Array<Company> = _.clone(state.companies);
      const payload = action.payload.payload;
      companies.splice(0, 0, payload);

      return {
        ...state,
        companies: companies,
      };
    }

    case ACTION_EMPTY_COMPANIES: {
      return initialState;
    }

    case ACTION_GET_COMPANIES_SUCCESS: {
      let companies: Array<Company> = _.clone(state.companies);
      let loadedAll = state.loadedAll;

      const payload = action.payload.payload;

      if (payload.length < QUERY_LIMIT) {
        loadedAll = true;
      }

      _.each(payload, (t) => {
        const company: Company = {
          id: t.id,
          name: t.name,
          legalName: t.legalName,
          primaryDomain: t.primaryDomain,
          agency: _.get(t, 'agency', {}),
        };

        companies.push(company);
      });

      return {
        ...state,
        companies: companies,
        loadedAll: loadedAll,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}
