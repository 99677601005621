import React, { useState, useEffect } from 'react';

const styles = require('./styles.pcss');
import cx from 'classnames';

type Props = {
  text: string;
  onClick: Function;
  className?: string;
  disabled?: boolean;
};

const TbButton = (props: Props) => {
  return (
    <div
      className={cx(props.className, styles.tbButton)}
      onClick={() => props.onClick()}
    >
      {props.text}
    </div>
  );
};

export default TbButton;
