import {
  ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING,
  ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING_SUCCESS,
  ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING_FAILURE,
} from '../configs/ActionTypes';

const initialState = {
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ACTION_BOOKINGS_SYNC_DUFFEL_BOOKING_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}
