import * as React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash-es';
import { navigate } from '@reach/router';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { CreditCard } from 'reducers/types';
const styles = require('./styles.pcss');
import SvgAlertIcon from '../../../static/icons/svgAlertIcon';
import DefaultCell from './DefaultCell';

type Props = {
  card: any;
  clickEdit?: any;
  editEnabled?: boolean;
  missingBillingAddress?: boolean;
  missingBillingAddressExists?: boolean;
};

const displayDefault = (card: any) => {
  let defaultText = '';
  if (card.default) {
    defaultText = card.defaultDepartmentId ? 'Department Default' : 'Default';
  }
  return (
    <TableCell
      key={'default' + card.id}
      className={classNames(styles.tableCell)}
    >
      {defaultText}
    </TableCell>
  );
};

const CCTableRow = (props: Props) => {
  let name = '';
  let nameStyle = {};
  let navigateToUser = () => {};
  if (typeof props.card.user !== 'undefined') {
    name = `${_.get(props.card, 'user.firstName', '')} ${_.get(
      props.card,
      'user.lastName',
      '',
    )}`;
    nameStyle = { cursor: 'pointer' };

    if (name.trim() === '') {
      name = _.get(props.card, 'user.email', '');
    }

    navigateToUser = () => navigate(`/users/${props.card.user.id}`);
  }

  let departments = '';
  if (
    _.isArray(props.card.departmentIds) &&
    props.card.departmentIds.length > 0
  ) {
    let s = '';
    if (props.card.departmentIds.length > 1) {
      s = 's';
    }

    departments = `${props.card.departmentIds.length} Department${s}`;
  }

  let classN = props.missingBillingAddress
    ? classNames(styles.tableCell, styles.extraMargin)
    : classNames(styles.tableCell);

  return (
    <TableRow className={classNames(styles.tableRow)}>
      {props.missingBillingAddress ? (
        <TableCell
          key={'type'}
          className={classNames(styles.tableCell)}
        >
          {
            <div className={styles.alertMessageCont}>
              {' '}
              <div className={styles.alertMessageContInside}>
                <SvgAlertIcon
                  width={16}
                  height={16}
                />
                {props.card.typeFull}... {props.card.last4}
              </div>{' '}
              <span className={styles.alertMessage}>
                Missing Billing Address
              </span>
            </div>
          }
        </TableCell>
      ) : (
        <TableCell
          key={'type'}
          className={classN}
        >{`${props.card.typeFull}... ${props.card.last4}`}</TableCell>
      )}
      <TableCell
        style={nameStyle}
        onClick={navigateToUser}
        key={'name'}
        className={classNames(styles.tableCell)}
      >
        {name}
      </TableCell>
      <TableCell
        key={'departments'}
        className={classNames(styles.tableCell)}
      >
        {departments}
      </TableCell>
      <TableCell
        key={'expiration'}
        className={classNames(styles.tableCell)}
      >{`Exp: ${moment(props.card.expiry).format('MM/YY')}`}</TableCell>
      <DefaultCell card={props.card} />
      {props.editEnabled && (
        <TableCell
          key={'rem'}
          className={classNames(styles.tableCell, styles.removeCell)}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={(e) => props.clickEdit(props.card)}
            className={styles.buttonEdit}
          >
            Edit
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
};

export default CCTableRow;
