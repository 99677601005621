exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".components-EditBookingTravelersModal-styles___editBookingTravelersModal--2GVmX {\n  background-color: white;\n  width: 50%;\n  position: absolute;\n  top: 25%;\n  left: 25%;\n  padding: 20px;\n  text-align: left;\n  max-height: 500px;\n  overflow-y: auto;\n}\n\n  .components-EditBookingTravelersModal-styles___editBookingTravelersModal--2GVmX h1 {\n    font-weight: 600;\n    font-size: 20px;\n  }\n\n  .components-EditBookingTravelersModal-styles___editBookingTravelersModal--2GVmX p {\n    margin-left: 10px;\n  }\n\n  .components-EditBookingTravelersModal-styles___editBookingTravelersModal--2GVmX .components-EditBookingTravelersModal-styles___checkboxContainer--KqU43 {\n    margin: 0px 0px 10px 10px;\n  }\n\n  .components-EditBookingTravelersModal-styles___editBookingTravelersModal--2GVmX .components-EditBookingTravelersModal-styles___buttonContainer--27JfX {\n    display: flex;\n    justify-content: space-between;\n  }\n", "", {"version":3,"sources":["/tmp/build_c0b3ce23/src/components/EditBookingTravelersModal/styles.pcss"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,WAAW;EACX,mBAAmB;EACnB,SAAS;EACT,UAAU;EACV,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;CAClB;;EAEC;IACE,iBAAiB;IACjB,gBAAgB;GACjB;;EAED;IACE,kBAAkB;GACnB;;EAED;IACE,0BAA0B;GAC3B;;EAED;IACE,cAAc;IACd,+BAA+B;GAChC","file":"styles.pcss","sourcesContent":[".editBookingTravelersModal {\n  background-color: white;\n  width: 50%;\n  position: absolute;\n  top: 25%;\n  left: 25%;\n  padding: 20px;\n  text-align: left;\n  max-height: 500px;\n  overflow-y: auto;\n}\n\n  .editBookingTravelersModal h1 {\n    font-weight: 600;\n    font-size: 20px;\n  }\n\n  .editBookingTravelersModal p {\n    margin-left: 10px;\n  }\n\n  .editBookingTravelersModal .checkboxContainer {\n    margin: 0px 0px 10px 10px;\n  }\n\n  .editBookingTravelersModal .buttonContainer {\n    display: flex;\n    justify-content: space-between;\n  }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"editBookingTravelersModal": "components-EditBookingTravelersModal-styles___editBookingTravelersModal--2GVmX",
	"checkboxContainer": "components-EditBookingTravelersModal-styles___checkboxContainer--KqU43",
	"buttonContainer": "components-EditBookingTravelersModal-styles___buttonContainer--27JfX"
};