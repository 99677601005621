import * as React from 'react';
import classNames from 'classnames';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import SvgIconPencil from '../../static/icons/svgIconPencil';

import { navigate } from '@reach/router';
import { hasPermission } from 'helpers/Permission';
import SupportLoginButton from 'components/SupportLogin/SupportLogin';

const styles = require('./styles.pcss');

type Props = {
  tbRoles: string;
  departments: string;
  manager: string;
  travelPolicy: string;
  employeeId: string;
  status: string;
  userId: string;
  selected: Array<string>;
  avatar: any;
  employeeEmail: string;
  employeeName: string;
  handleChangeCheckbox: any;
  isInvited: any;
  showEdit: any;
  clickEdit?: any;
  tbRoleStatus: any;
  isSolo: boolean;
};

const EmployeeListTableRow = (props: Props) => {
  const tableColumn = (name: string, i: number) => (
    <TableCell
      key={i}
      className={classNames(styles.tableCellEmpList)}
    >
      {name}
    </TableCell>
  );

  const isSelected = (id: string) => props.selected.indexOf(id) !== -1;

  const handleClick = (e: any) => {
    if (e.target.tagName != 'path' && e.target.tagName != 'svg') {
      navigate(`/users/${props.userId}`);
    }
  };

  const renderStatus = () => {
    let statusStyle = '';
    let statusText = '';

    if (props.tbRoleStatus === 'active' && props.status !== 'disabled') {
      statusStyle = styles.active;
      statusText = 'Active';
    }
    if (
      props.tbRoleStatus === 'pending' &&
      props.isInvited &&
      props.status !== 'disabled'
    ) {
      statusText = 'Invite Sent';
      statusStyle = styles.invite_sent;
    }
    if (
      (props.tbRoleStatus === 'pending' &&
        !props.isInvited &&
        props.status !== 'disabled') ||
      props.isSolo
    ) {
      statusText = 'Not Invited';
      statusStyle = styles.not_invited;
    }
    if (props.status === 'disabled') {
      statusText = 'Terminated';
      statusStyle = styles.not_invited;
    }

    return (
      <div className={classNames(styles.status_box, statusStyle)}>
        <div>{statusText}</div>
      </div>
    );
  };

  const renderCheckBox = () => {
    return (
      <TableCell
        className={classNames(
          styles.tableCellAvatar,
          styles.checkBox,
          styles.checkBoxPadding,
        )}
      >
        <Checkbox
          checked={isSelected(props.userId)}
          inputProps={{ 'aria-labelledby': props.userId }}
          onChange={props.handleChangeCheckbox}
        />
      </TableCell>
    );
  };

  const displayEdit = (props: Props) => {
    return (
      <TableCell
        onClick={() => {
          props.clickEdit;
        }}
        key={'edit'}
        className={classNames(styles.tableCellEmpList, styles.editCell)}
      >
        <div onClick={props.clickEdit}>
          <SvgIconPencil
            width={16}
            height={16}
          />
        </div>
      </TableCell>
    );
  };

  return (
      <TableRow
        className={classNames(styles.tableRow, styles.tableRowEmp)}
        onClick={handleClick}
      >
        <TableCell className={classNames(styles.cellHide)}></TableCell>
        <TableCell className={classNames(styles.tableCellAvatar)}>
          {props.avatar}
        </TableCell>
        <TableCell className={classNames(styles.tableCellEmpList)}>
          <div className={styles.name}>{props.employeeName}</div>
          <div className={styles.details}>{props.employeeEmail}</div>
          <div className={styles.details}>ID: {props.employeeId}</div>
        </TableCell>
        <TableCell className={classNames(styles.tableCellEmpList)}>
          {props.tbRoles}
        </TableCell>
        <TableCell className={classNames(styles.tableCellEmpList)}>
          {props.departments}
        </TableCell>
        <TableCell className={classNames(styles.tableCellEmpList)}>
          {props.manager}
        </TableCell>
        <TableCell className={classNames(styles.tableCellEmpList)}>
          {props.travelPolicy}
        </TableCell>
        <TableCell
          key={'rem'}
          className={classNames(styles.tableCellEmpList)}
        >
          {renderStatus()}
        </TableCell>
        {props.showEdit && displayEdit(props)}
        <TableCell className={classNames(styles.tableCellEmpList)} onClick={(event) => event.stopPropagation()}>
          {hasPermission('customerLogin', 'read') && props.userId && props.tbRoleStatus === 'active' && <SupportLoginButton userId={props.userId} />}
        </TableCell>
        <TableCell className={classNames(styles.cellHide)}></TableCell>
      </TableRow>
  );
};

export default EmployeeListTableRow;
