import * as React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getSingle, emptyResults } from 'actions/Agencies/GetSingle';
import { putFeatures } from 'actions/Features/PutAgencyBetaFeature';
import { hasPermission } from '../../helpers/Permission';
import { getFeatures, resetFeatures } from 'actions/Features/Get';
import { navigate } from '@reach/router';
import _ from 'lodash-es';
import AgencyInfo from 'components/AgencyDetails/AgencyInfo';
import AgencySupportContact from 'components/AgencyDetails/AgencySupportContact';
import AppAppearance from 'components/AgencyDetails/AppAppearance';
import LayoutDashboard from 'components/Layout/Dashboard';
import Loader from 'components/Loader/Loader';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumb';
const styles = require('./styles.pcss');
import { putSingle } from 'actions/Agencies/PutSingle';
import AgencyInventorySuppliers from 'components/AgencyDetails/AgencyInventorySuppliers';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import CorporateRateCodes from 'components/CorporateRateCodes/CorporateRateCodes';
import BetaFeatures from 'components/Features/BetaFeatures';
import { TB_BLACKBOX_ENV } from '../../configs/Api';
import { ReduxState } from 'reducers/types';

type Props = {
  path: string;
  agencyId?: string;
  getSingle: Function;
  emptyResults: Function;
  agencyDetails?: any;
  isLoading: any;
  putSingle: Function;
  isLoadingColor: any;
  getFeatures: Function;
  resetFeatures: Function;
  putFeatures: Function;
  isLoadingFeatures: boolean;
  features: any;
};

type State = {
  tabValue: string;
};

class AgencySingle extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tabValue: 'profile',
    };

    let param: any = {
      scopeType: 'agency',
    };

    if (TB_BLACKBOX_ENV === 'production') {
      param.confidence = 'production';
    }

    if (hasPermission('features', 'read')) {
      this.props.getFeatures(param);
    }
  }

  componentDidMount() {
    this.props.getSingle(this.props.agencyId);
  }

  componentWillUnmount() {
    this.props.emptyResults();
    this.props.resetFeatures();
  }

  handleTabChange = (event: any, value: any) => {
    this.setState({
      tabValue: value,
    });
  };

  putCorporateRateCodes = (value: any) => {
    this.props.putSingle(_.get(this.props, 'agencyDetails.id', null), {
      id: _.get(this.props, 'agencyDetails.id', null),
      'settings.hotels.travelport.rateCodes': _.get(value, 'value.hotels', []),
      'settings.flights.travelport.snapCodes': _.get(
        value,
        'value.flights',
        [],
      ),
    });
  };

  handleFeatureChange = (feature: any, checked: boolean) => {
    let param = {
      agencyId: _.get(this.props, 'agencyDetails.id', null),
      featureName: feature.name,
      confidence: feature.confidence,
      enabled: checked,
    };

    this.props.putFeatures(param);
  };

  renderTab = () => {
    const disabled = !hasPermission('agency', 'update');

    switch (this.state.tabValue) {
      case 'profile':
        return (
          <div>
            <AgencyInfo
              agency={this.props.agencyDetails}
              disabled={disabled}
            />
            {!_.get(
              this.props.agencyDetails,
              'uiSettings.isTravelBank',
              false,
            ) && (
              <AppAppearance
                agency={this.props.agencyDetails}
                disabled={disabled}
                isLoading={this.props.isLoadingColor}
              />
            )}
            <AgencySupportContact
              agency={this.props.agencyDetails}
              disabled={disabled}
            />
          </div>
        );
        break;
      case 'beta':
        const features = _.get(this.props, 'features', []);
        return (
          <BetaFeatures
            features={features}
            agencyId={_.get(this.props, 'agencyDetails.id', null)}
            type="agency"
            onHandleChange={this.handleFeatureChange}
          />
        );
        break;
      case 'travel':
        return (
          <>
            <Paper
              className={
                this.props.isLoading
                  ? cx(styles.paper, styles.loading)
                  : styles.paper
              }
            >
              <div className={styles.agencySetting}>
                <AgencyInventorySuppliers
                  isLoading={this.props.isLoading}
                  agencyDetails={_.get(
                    this.props.agencyDetails,
                    'settings',
                    {},
                  )}
                  putSpecialRateCodes={this.onSubmitUpdate}
                  putAgency={this.onSubmitUpdate}
                />
              </div>
            </Paper>

            <CorporateRateCodes
              putCorporateCodes={this.putCorporateRateCodes}
              isLoading={this.props.isLoading}
              hasPermission={hasPermission('agency', 'update')}
              hotelCodes={_.get(
                this.props.agencyDetails,
                'settings.hotels.travelport.rateCodes',
                [],
              )}
              flightCodes={_.get(
                this.props.agencyDetails,
                'settings.flights.travelport.snapCodes',
                [],
              )}
            />
          </>
        );
        break;
      default:
        return null;
        break;
    }
  };

  shouldComponentUpdate(nextProps: any) {
    if (!hasPermission('agency', 'read')) {
      navigate(`/lookup`);
      return false;
    }
    return true;
  }

  onSubmitUpdate = (agencySetting: any) => {
    this.props.putSingle(_.get(this.props, 'agencyDetails.id', null), {
      id: _.get(this.props, 'agencyDetails.id', null),
      name: _.get(this.props, 'agencyDetails.name', ''),
      appHost: _.get(this.props, 'agencyDetails.appHost', ''),
      settings: agencySetting,
      uiSettings: _.get(this.props, 'agencyDetails.uiSettings', ''),
    });
  };

  render() {
    if (this.props.isLoading) {
      return (
        <LayoutDashboard>
          <div className="pageWrapper">
            <Loader visible={this.props.isLoading} />
          </div>
        </LayoutDashboard>
      );
    }

    return (
      <LayoutDashboard>
        <div>
          <Breadcrumbs className={styles.breadcrumbs}>
            <Breadcrumb
              arrow
              name="Lookup"
            />
            <Breadcrumb
              name={_.get(this.props, 'agencyDetails.friendlyName', '')}
            />
          </Breadcrumbs>
        </div>

        <div className="pageWrapper pageWrapperMid">
          <div className={styles.agencyNameContainer}>
            <h1>{_.get(this.props, 'agencyDetails.friendlyName', '')}</h1>
          </div>
        </div>

        <Tabs
          value={this.state.tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleTabChange}
          className={cx(styles.tabs, styles.loyalityTabs)}
        >
          <Tab
            disableRipple
            className={styles.tab}
            label="Profile"
            value="profile"
          />
          {hasPermission('features', 'read') && (
            <Tab
              disableRipple
              className={styles.tab}
              label="Beta"
              value="beta"
            />
          )}
          <Tab
            disableRipple
            className={styles.tab}
            label="Travel"
            value="travel"
          />
        </Tabs>

        {this.renderTab()}
      </LayoutDashboard>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    agencyDetails: state.agency.agency,
    isLoading: state.agency.isLoading,
    isLoadingColor: state.agency.isLoadingColor,
    features: state.betaFeatures.features,
    isLoadingFeatures: state.betaFeatures.isLoading,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getSingle,
      emptyResults,
      resetFeatures,
      putSingle,
      putFeatures,
      getFeatures,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AgencySingle);
