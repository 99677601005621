/**
 * GET User Action
 */
import _ from 'lodash-es';

import {
  ACTION_GET_USER,
  ACTION_GET_USER_SUCCESS,
  ACTION_GET_USER_FAILURE,
  API_CALL,
} from '../../configs/ActionTypes';

import { Dispatch } from 'redux';
import { navigate } from '@reach/router';
import { enqueueSnackbar } from '../Status';

/**
 * GET User Request Action
 */
export function getSingle(userId: string) {
  return (dispatch: Dispatch, getState: Function) => {
    // Dispatch the api call request.
    dispatch(requestStarted());

    dispatch({
      type: API_CALL,
      payload: {
        call: `user/${userId}`,
        method: 'get',
        success: (response: any) => {
          if (response.type === 'error') {
            if (response.payload.userDetailsNotFound === true) {
              navigate(`/lookup`);
              return;
            }

            let errors = response.payload.payload;
            Object.keys(errors).forEach(function (item) {
              if (errors[item] == true) {
                dispatch(enqueueSnackbar(item, 'error'));
              }
            });
            dispatch(requestFailed(response));
          } else {
            dispatch(requestSuccess(response));
          }
        },
        fail: (error: any) => {
          let errors = error.payload;

          if (error.payload.userDetailsNotFound === true) {
            navigate(`/lookup`);
            return;
          }

          Object.keys(errors).forEach(function (item) {
            if (errors[item] == true) {
              dispatch(enqueueSnackbar(item, 'error'));
            }
          });
          dispatch(requestFailed(error));
        },
      },
    });

    return null;
  };
}

export function requestStarted() {
  return {
    type: ACTION_GET_USER,
    payload: null,
  };
}

export function requestSuccess(responseJSON: object) {
  return {
    type: ACTION_GET_USER_SUCCESS,
    payload: responseJSON,
  };
}

export function requestFailed(responeJSON: object) {
  return {
    type: ACTION_GET_USER_FAILURE,
    payload: responeJSON,
  };
}
