exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".components-Breadcrumbs-styles___breadcrumbs--FMDqL {\n  display: flex;\n  align-items: center;\n}\n\n  .components-Breadcrumbs-styles___breadcrumbs--FMDqL .components-Breadcrumbs-styles___breadcrumb--1Mhsr {\n    color: #6d717a;\n    font-size: 13px;\n    padding: 0 6px 0 0;\n    display: flex;\n    align-items: center;\n  }\n\n  .components-Breadcrumbs-styles___breadcrumbs--FMDqL .components-Breadcrumbs-styles___breadcrumb--1Mhsr.components-Breadcrumbs-styles___breadcrumbLink--kGir9 {\n      cursor: pointer;\n    }\n\n  .components-Breadcrumbs-styles___breadcrumbs--FMDqL .components-Breadcrumbs-styles___icon--wxiN- {\n    width: 10px;\n    margin-left: 6px;\n  }\n", "", {"version":3,"sources":["/tmp/build_188bc313/src/components/Breadcrumbs/styles.pcss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;CACrB;;EAEC;IACE,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;IACd,oBAAoB;GACrB;;EAED;MACI,gBAAgB;KACjB;;EAEH;IACE,YAAY;IACZ,iBAAiB;GAClB","file":"styles.pcss","sourcesContent":[".breadcrumbs {\n  display: flex;\n  align-items: center;\n}\n\n  .breadcrumbs .breadcrumb {\n    color: #6d717a;\n    font-size: 13px;\n    padding: 0 6px 0 0;\n    display: flex;\n    align-items: center;\n  }\n\n  .breadcrumbs .breadcrumb.breadcrumbLink {\n      cursor: pointer;\n    }\n\n  .breadcrumbs .icon {\n    width: 10px;\n    margin-left: 6px;\n  }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"breadcrumbs": "components-Breadcrumbs-styles___breadcrumbs--FMDqL",
	"breadcrumb": "components-Breadcrumbs-styles___breadcrumb--1Mhsr",
	"breadcrumbLink": "components-Breadcrumbs-styles___breadcrumbLink--kGir9",
	"icon": "components-Breadcrumbs-styles___icon--wxiN-"
};