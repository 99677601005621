exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".pages-RefundChargeGoogleForm-styles___header--MJcIV {\n  margin-top: 64px;\n}\n", "", {"version":3,"sources":["/tmp/build_188bc313/src/pages/RefundChargeGoogleForm/styles.pcss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;CAClB","file":"styles.pcss","sourcesContent":[".header {\n  margin-top: 64px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"header": "pages-RefundChargeGoogleForm-styles___header--MJcIV"
};