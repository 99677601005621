import * as React from 'react';
const styles = require('../Popup/styles.pcss');

interface Props {
  card: any;
}

export default function CorporateLabel(props: Props) {
  let corporate = '';
  const creditCard = props.card;
  if (
    creditCard.organizationId ||
    (Array.isArray(creditCard.departmentIds) &&
      creditCard.departmentIds.length > 0)
  ) {
    let defaultText = '';
    if (creditCard.default) {
      defaultText = creditCard.defaultDepartmentId
        ? ' (Department Default)'
        : ' (Default)';
    }
    corporate = '• Corporate' + defaultText;
  }

  return corporate ? (
    <span className={styles.corporateMark}>{corporate}</span>
  ) : (
    <></>
  );
}
