import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import IconCheckboxChecked from '../../static/icons/svgCheckboxChecked';
import IconCheckbox from '../../static/icons/svgCheckbox';
import TbButton from 'components/TbButton/TbButton';
const styles = require('./styles.pcss');

type Props = {
  userTravelers: any[];
  currentBookingTravelers: any[];
  isOpen: boolean;
  closeModal: Function;
  saveTravelers: Function;
};

const EditBookingTravelersModal = (props: Props) => {
  const [travelers, setTravelers] = useState([] as any[]);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const toggleSelect = (travelerId: string) => {
    setShowEmptyError(false);
    setTravelers(
      travelers.map((trav) =>
        trav.id === travelerId ? { ...trav, selected: !trav.selected } : trav,
      ),
    );
  };

  useEffect(() => {
    setTravelers(
      props.userTravelers.map((trav) => ({
        lable: `${trav.firstName} ${trav.lastName} (${trav.email})`,
        id: trav.id,
        selected: props.currentBookingTravelers
          .map((trav) => trav.id)
          .includes(trav.id),
      })),
    );
  }, [props.userTravelers]);

  const save = () => {
    if (travelers.filter((trav) => trav.selected).length < 1) {
      setShowEmptyError(true);
      return;
    }
    props.saveTravelers(
      travelers.filter((trav) => trav.selected).map((trav) => trav.id),
    );
  };

  const close = () => {
    setTravelers(
      props.userTravelers.map((trav) => ({
        lable: `${trav.firstName} ${trav.lastName} (${trav.email})`,
        id: trav.id,
        selected: props.currentBookingTravelers
          .map((trav) => trav.id)
          .includes(trav.id),
      })),
    );
    props.closeModal();
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={() => close()}
    >
      <Paper className={styles.editBookingTravelersModal}>
        <h1>Select travelers for this booking</h1>
        <p>
          Note, this only updates the traveler in our system, and does NOT
          update the travelers associated to the booking in the gds or in
          duffel.
        </p>
        <FormGroup className={styles.checkboxContainer}>
          {travelers.map((trav) => (
            <FormControlLabel
              control={
                <Checkbox
                  icon={
                    <IconCheckbox
                      width={16}
                      height={16}
                    />
                  }
                  checkedIcon={
                    <IconCheckboxChecked
                      width={16}
                      height={16}
                    />
                  }
                  checked={trav.selected}
                  onChange={(event) => toggleSelect(event.target.value)}
                  value={trav.id}
                />
              }
              label={trav.lable}
            />
          ))}
        </FormGroup>
        {showEmptyError && (
          <p className="error">Please select at least one traveler</p>
        )}
        <div className={styles.buttonContainer}>
          <TbButton
            className={styles.saveButton}
            onClick={close}
            text="Close"
          />
          <TbButton
            className={styles.saveButton}
            onClick={save}
            text="Save"
          />
        </div>
      </Paper>
    </Modal>
  );
};

export default EditBookingTravelersModal;
