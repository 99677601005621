import * as React from 'react';
import _ from 'lodash-es';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from 'reducers/types';
import { putSingle as updateUser } from 'actions/Users/PutSingle';
import { updateBooking } from 'actions/Bookings/UpdateBooking';
import { update as updateCompany } from '../../actions/Companies/Update';
import { useState } from 'react';

const styles = require('../BookingDetails/styles.pcss');

type Props = {
  id: any;
  entity: any;
  type: any;
  updateUser: Function;
  updateBooking: Function;
  updateCompany: Function;
};

type State = {
  notes: string;
  isEditing: boolean;
};

class Notes extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const note = _.get(this.props.entity, 'notes', '');

    this.state = {
      notes: note,
      isEditing: note === '' ? true : false,
    };
  }

  componentWillReceiveProps = (nextProps: Props) => {
    this.setState({
      notes: _.get(nextProps.entity, 'notes', ''),
      isEditing: _.get(nextProps.entity, 'notes', '') === '' ? true : false,
    });
  };

  onSubmit = () => {
    const notes = this.state.notes;

    if (this.props.type === 'user') {
      const userData: any = {
        bypassSso: _.get(this.props.entity, 'bypassSso', ''),
        firstName: _.get(this.props.entity, 'firstName', ''),
        lastName: _.get(this.props.entity, 'lastName', ''),
        phoneNumber: _.get(this.props.entity, 'phoneNumber', ''),
        email: _.get(this.props.entity, 'email', ''),
        notes: notes,
      };
      this.props.updateUser(this.props.id, userData);
    } else if (this.props.type === 'booking') {
      const bookingStatus = _.get(this.props.entity, 'status', '');
      const confirmationNumber = _.get(
        this.props.entity,
        'confirmationNumber',
        '',
      );
      const bookingEmail = _.get(this.props.entity, 'user.email', '');

      this.props.updateBooking({
        bookingId: this.props.id,
        status: bookingStatus,
        confirmationNumber,
        userEmail: bookingEmail,
        notes,
      });
    } else if (this.props.type === 'organization') {
      this.props.updateCompany({
        id: this.props.id,
        name: _.get(this.props.entity, 'name', ''),
        primaryDomain: this.props.entity.primaryDomain,
        companyCode: this.props.entity.companyCode,
        size: this.props.entity.size,
        street: this.props.entity.address.street,
        street2: this.props.entity.address.street2,
        city: this.props.entity.address.city,
        country: this.props.entity.address.country,
        zip: this.props.entity.address.zip,
        state: this.props.entity.address.state,
        additionalDomains: this.props.entity.additionalDomains,
        notes: notes,
      });
    }
  };

  handleEditSave = () => {
    if (this.state.isEditing) {
      this.setState({ isEditing: false });
      this.onSubmit();
    } else {
      this.setState({ isEditing: true });
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ notes: event.target.value });
  };

  render() {
    const { notes, isEditing } = this.state;
    const wider = this.props.type === 'user';

    return (
      <Paper
        className={`${styles.bookingDetails} ${wider ? styles.widerNotes : ''}`}
      >
        <div className={styles.header}>
          <h1 className={styles.additionalDetailsTitle}>Notes</h1>
        </div>
        <div className={styles.additionalBookingContainer}>
          <div className={styles.tagsContainer}>
            {isEditing ? (
              <textarea
                value={notes}
                onChange={this.handleChange}
                className={styles.textarea}
                placeholder="Add notes here..."
              />
            ) : (
              <div>
                <p className={styles.noteText}>
                  {notes?.split('\n')?.map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              </div>
            )}
          </div>
        </div>
        <div
          className={styles.editButton}
          onClick={this.handleEditSave}
        >
          {isEditing ? 'Save' : 'Edit'}
        </div>
      </Paper>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    userLoading: state.user.isLoading,
    updateSuccess: state.user.updateSuccess,
    isLoading: state.bookings.isLoading,
    saveCompanyStatus: state.saveCompanyStatus,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateUser,
      updateBooking,
      updateCompany,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(Notes);
