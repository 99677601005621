import {
  ACTION_GET_COMPANY,
  ACTION_GET_COMPANY_SUCCESS,
  ACTION_GET_COMPANY_FAILURE,
  ACTION_UPDATE_COMPANY_EMPLOYEES,
  ACTION_EMPTY_COMPANY,
  ACTION_PUT_COMPANY_SUCCESS,
  ACTION_POST_DEPARTMENT_SUCCESS,
} from '../configs/ActionTypes';

import { Company } from './types';

import _ from 'lodash-es';

const initialState = {
  company: {
    employees: [],
    departments: [],
    creditCards: [],
  },
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_COMPANY: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTION_EMPTY_COMPANY: {
      return initialState;
    }

    case ACTION_POST_DEPARTMENT_SUCCESS: {
      const department = action.payload.payload;
      let company: any = state.company;

      company.departments.push(department);

      return {
        ...state,
        company: company,
      };
    }

    case ACTION_GET_COMPANY_SUCCESS: {
      const p = action.payload.payload;

      const company: Company = {
        id: p.id,
        name: p.name,
        legalName: p.legalName,
        primaryDomain: p.primaryDomain,
        employees: _.get(p, 'employees', []),
        size: _.get(p, 'size', null),
        departments: _.get(p, 'departments', []),
        additionalDomains: _.get(p, 'additionalDomains', []),
        creditCards: _.get(p, 'creditCards', []),
        companyCode: _.get(p, 'companyCode', ''),
        travelPolicies: _.get(p, 'travelPolicies', []),
        expensePolicies: _.get(p, 'expensePolicies', []),
        agency: _.get(p, 'agency', null),
        address: _.get(p, 'address', null),
        notes: _.get(p, 'notes', ''),
      };

      return {
        ...state,
        company: {
          ...state.company,
          ...company,
        },
        isLoading: false,
        success: true,
      };
    }

    case ACTION_UPDATE_COMPANY_EMPLOYEES: {
      const p = action.payload.payload;

      _.find(state.company.employees, function (o: any) {
        if (o.id == p.id) {
          _.set(o, 'status', _.get(p, 'status', ''));
        }
      });

      return {
        ...state,
        company: state.company,
        isLoading: false,
      };
    }

    case ACTION_PUT_COMPANY_SUCCESS: {
      const p = action.payload.payload;
      let compCode = _.get(p, 'companyCode', '');
      compCode =
        compCode == '' ? _.get(state.company, 'companyCode', '') : compCode;

      const company: Company = {
        id: p.id,
        name: p.name,
        legalName: p.legalName,
        primaryDomain: p.primaryDomain,
        additionalDomains: _.get(p, 'additionalDomains', []),
        employees: _.get(state.company, 'employees', undefined),
        size: _.get(p, 'size', null),
        address: _.get(p, 'address', null),
        departments: _.get(state.company, 'departments', undefined),
        creditCards: _.get(state.company, 'creditCards', undefined),
        travelPolicies: _.get(state.company, 'travelPolicies', []),
        expensePolicies: _.get(p, 'expensePolicies', []),
        agency: _.get(p, 'agency', null),
        companyCode: compCode,
        notes: _.get(p, 'notes', ''),
      };

      return {
        ...state,
        company: {
          ...state.company,
          ...company,
        },
      };
    }

    default: {
      return state;
    }
  }
}
