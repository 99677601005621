import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TravelPolicyTableRows from './TravelPolicyTableRows';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Loader from 'components/Loader/Loader';

const styles = require('./styles.pcss');

type Props = {
  isUpdatedTravelPolicyEnabled: boolean;
  flightPolicy: any;
  hotelPolicy: any;
  isLoading: any;
};

const advanceBooking: any = {
  name: 'Advance Booking',
  description:
    'Number of days in advance a booking must be made to be considered in policy.',
};

const hardCap: any = {
  name: 'Hard Cap',
  description: 'Maximum amount a booking can be to be considered in policy.',
};

class BookingRules extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  onFormatAdvancedBooking(val: string) {
    if (val === '' || !val) {
      return '-';
    }

    return val + ' Days';
  }

  onFormatOverBudget(val: string) {
    if (val === '' || !val) {
      return '-';
    }

    return `$${val}`;
  }

  render() {
    const {
      isLoading,
      isUpdatedTravelPolicyEnabled,
      flightPolicy,
      hotelPolicy,
    } = this.props;

    let flightAdvanceBooking,
      hotelAdvanceBooking,
      flightOverBudget,
      hotelOverBudget;
    if (!isUpdatedTravelPolicyEnabled) {
      const daysInAdvanceQuery = 'rules.daysInAdvance.value';
      const flightDaysInAdvance = _.get(flightPolicy, daysInAdvanceQuery, '');
      const hotelDaysInAdvance = _.get(hotelPolicy, daysInAdvanceQuery, '');

      const overValueQuery = 'rules.overValue.value';
      const flightOverBudgetValue = _.get(flightPolicy, overValueQuery, '');
      const hotelOverBudgetValue = _.get(hotelPolicy, overValueQuery, '');

      flightAdvanceBooking = this.onFormatAdvancedBooking(flightDaysInAdvance);
      hotelAdvanceBooking = this.onFormatAdvancedBooking(hotelDaysInAdvance);

      flightOverBudget = this.onFormatOverBudget(flightOverBudgetValue);
      hotelOverBudget = this.onFormatOverBudget(hotelOverBudgetValue);
    }

    if (isUpdatedTravelPolicyEnabled) {
      const flight = flightPolicy?.policyRules?.flight;
      const hotel = flightPolicy?.policyRules?.hotel;

      flightAdvanceBooking = this.onFormatAdvancedBooking(
        flight?.daysInAdvance?.value,
      );
      hotelAdvanceBooking = this.onFormatAdvancedBooking(
        hotel?.daysInAdvance?.value,
      );

      flightOverBudget = this.onFormatOverBudget(flight?.overValue?.value);
      hotelOverBudget = this.onFormatOverBudget(hotel?.overValue?.value);
    }

    return (
      <Paper
        className={isLoading ? cx(styles.paper, styles.loading) : styles.paper}
      >
        <div className={styles.policyRules}>
          <h2>Booking Rules</h2>
          <div>
            <Loader visible={isLoading} />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="right"
                  className={cx(styles.tableCell, styles.header)}
                ></TableCell>
                <TableCell
                  align="center"
                  className={cx(styles.tableCell, styles.header)}
                >
                  Flight
                </TableCell>
                <TableCell
                  align="center"
                  className={cx(styles.tableCell, styles.header)}
                >
                  Lodging
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TravelPolicyTableRows
                description
                key={1}
                text={advanceBooking}
                flight={flightAdvanceBooking}
                hotel={hotelAdvanceBooking}
              />
              <TravelPolicyTableRows
                description
                key={2}
                text={hardCap}
                flight={flightOverBudget}
                hotel={hotelOverBudget}
              />
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

export default BookingRules;
