import * as React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getSingle } from 'actions/Companies/GetSingle';
import {
  resetImportEmployees,
  confirmCSVImport,
} from 'actions/Companies/ImportEmployees';
import { getFeatures, resetFeatures } from 'actions/Features/Get';
import { getOrganizationsProductsSettings } from 'actions/Features/GetOrganziationsProductsSettings';
import { getFraudRule } from 'actions/Features/GetFraudCheck';
import { updateOrganizationFraudRule } from 'actions/Features/PutOrganizationFraudRule';
import { putFeatures } from 'actions/Features/Put';
import { getBasicEconomyFeature } from 'actions/Features/GetBasicEconomy';
import { hasPermission } from '../../helpers/Permission';
import { putOrganizationsProductsSettings } from 'actions/Features/PutOrganizaitonsProductsSettings';
import { getReimbursement } from 'actions/Features/GetReimbursement';
import { putReimbursement } from 'actions/Features/PutReimbursement';
import { getReimbursementSettings } from 'actions/Features/GetReimbursementSettings';
import { putReimbursementSettings } from 'actions/Features/PutReimbursementSettings';
import { putBasicEconomy } from 'actions/Features/PutBasicEconomy';
import { getCorporateRateCodes } from 'actions/Companies/Settings/GetCorporateRateCodes';
import { getFlightTourCodes } from 'actions/Companies/Settings/GetFlightTourCodes';
import { getNegotiatedHotels } from 'actions/Companies/GetNegotiatedHotels';
import { getActiveExpenseIntegration } from 'actions/Companies/GetActiveExpenseIntegration';
import { getAgencySetting } from 'actions/Companies/Settings/GetAgencySetting';
import { putCorporateRateCodes } from '../../actions/Companies/Settings/PutCorporateRateCodes';
import { getActiveExpenseManagenentIntegration } from 'actions/Companies/GetActiveExpenseManagement';
import { getDefaultExpensePolicy } from 'actions/Companies/GetDefaultExpensePolicy';
import { getCarLoyaltyProgram } from '../../actions/LoyaltyPrograms/GetCarLoyaltyProgram';
import { navigate } from '@reach/router';
import { getBankAccount } from 'actions/Companies/GetBankAccount';
import { getFlightBookingWindowFeature } from 'actions/Features/GetFlightBookingWindow';
import { deleteFlightBookingFeature } from 'actions/Features/DeleteFlightBookingWindow';
import { putFlightBookingFeature } from 'actions/Features/PutFlightBookingWindow';
import { getExpenseSplitSetting } from 'actions/Companies/Settings/GetExpenseSplitSetting';
import { putExpenseSplitSetting } from 'actions/Companies/Settings/PutExpenseSplitSetting';
import { putVerifiedForTravelSettings } from 'actions/Features/PutVerifiedForTravelSettings';
import { getVerifiedForTravelSettings } from 'actions/Features/GetVerifiedForTravelSettings';
import { getLicensePricing } from 'actions/Companies/Settings/GetLicensePricing';
import { getSpecialRateCodes } from 'actions/Companies/Settings/GetSpecialRateCodes';
import { getCarbonEmissionProducts } from 'actions/Companies/Settings/GetCarbonEmissionProducts';
import { addUpdateCarbonEmissionProduct } from 'actions/Companies/Settings/AddUpdateCarbonEmissionProduct';
import { getCarbonEmissionOrgProduct } from 'actions/Companies/Settings/GetCarbonEmissionOrgProduct';
import { getPaidPlanSetting } from 'actions/Companies/Settings/GetPaidPlan';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LayoutDashboard from 'components/Layout/Dashboard';
import CompanyInfo from 'components/CompanyDetails/CompanyInfo';
import LicensePricing from 'components/CompanyDetails/LicensePricing/LicensePricing';
import AdditionalDomains from 'components/CompanyDetails/AdditionalDomains/AdditionalDomains';
import OrganizationAgencySetting from 'components/CompanyDetails/OrganizationAgencySetting';
import CompanyFeatures from 'components/Features/Features';
import Reimbursements from 'components/Features/Reimbursements';
import BetaFeatures from 'components/Features/BetaFeatures';
import TravelFeatures from 'components/Features/TravelFeatures';
import EmployeeList from 'components/CompanyDetails/EmployeeList';
import CorporateCards from 'components/CompanyDetails/CorporateCards/CorporateCards';
import Loader from 'components/Loader/Loader';
import CorporateRateCodes from 'components/CorporateRateCodes/CorporateRateCodes';
import NegotiatedSuppliers from 'components/NegotiatedSuppliers/NegotiatedSuppliers';
import ActiveExpenseIntegration from 'components/CompanyDetails/ActiveExpenseIntegration';
import OnboardingComp from 'components/CompanyDetails/OnboardingComp';
import ImportEmployeesPopup from 'components/Popup/ImportEmployeesPopup';
import ImportEmployeesUpload from 'components/ImportEmployees/Upload';
import ImportEmployeesLoading from 'components/ImportEmployees/Loading';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumb';
import CreateUpdateCarbonEmissionProduct from 'components/CreateUpdateCarbonEmissionProduct/CreateUpdateCarbonEmissionProduct';
import { CarbonOffsetReduxState } from 'reducers/CarbonEmissionProducts';
import Notes from 'components/Notes/Notes';

const styles = require('./styles.pcss');

import { TB_BLACKBOX_ENV, TB_AGENCY_ID } from '../../configs/Api';

import { ReduxState, Company as CompanyType, Features } from 'reducers/types';
import RewardsProgram from 'components/CompanyDetails/CommercialRewards/RewardsProgram';
import isFeatureEnabled, { ScopeType } from '../../helpers/isFeatureEnabled';

type Props = {
  path: string;
  companyId?: string;
  getSingle: Function;
  putFeatures: Function;
  companyDetails: CompanyType;
  importEmployees: any;
  resetImportEmployees: Function;
  confirmCSVImport: Function;
  isLoading: any;
  getFeatures: Function;
  getOrganizationsProductsSettings: Function;
  getBasicEconomyFeature: Function;
  getCorporateRateCodes: Function;
  getFlightTourCodes: Function;
  putOrganizationsProductsSettings: Function;
  getReimbursement: Function;
  putReimbursement: Function;
  resetFeatures: Function;
  features: Array<Features>;
  organziationProductsSettings?: any;
  basicEconomyFeature?: any;
  reimbursementFeature?: any;
  advancedFlightBookingFeature?: any;
  reimbursementSettings?: any;
  corporateRateCodes?: any;
  carbonEmissionOffset: CarbonOffsetReduxState;
  microDepositSetting?: any;
  getNegotiatedHotels: Function;
  getActiveExpenseIntegration: Function;
  updateOrganizationFraudRule: Function;
  getFraudRule: Function;
  getReimbursementSettings: Function;
  putReimbursementSettings: Function;
  putBasicEconomy: Function;
  organizationActiveExpenseIntegration: any;
  getAgencySetting: Function;
  agencySetting?: any;
  putCorporateRateCodes: Function;
  getActiveExpenseManagenentIntegration: Function;
  organizationActiveExpenseManagement: any;
  getDefaultExpensePolicy: any;
  expensePolicy: any;
  getCarLoyaltyProgram: Function;
  carLoyaltyProgram: any;
  getBankAccount: any;
  organizationBankAccount: any;
  licensePricing: any;
  reloadPricing: boolean;
  getFlightBookingWindowFeature: Function;
  deleteFlightBookingFeature: Function;
  putFlightBookingFeature: Function;
  putExpenseSplitSetting: Function;
  getExpenseSplitSetting: Function;
  verifiedForTravelFeature: Function;
  putVerifiedForTravelSettings: Function;
  getVerifiedForTravelSettings: Function;
  getLicensePricing: Function;
  getSpecialRateCodes: Function;
  getCarbonEmissionProducts: Function;
  addUpdateCarbonEmissionProduct: Function;
  getCarbonEmissionOrgProduct: Function;
  specialRateCodes?: any;
  isFeatureFlagsLoading: boolean;
  isFeatureFlagsSuccess: boolean;
  getPaidPlanSetting: Function;
};

type State = {
  importEmployeesPopupOpen: boolean;
  tabValue: string;
};

class CompanyDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tabValue: 'profile',
      importEmployeesPopupOpen: false,
    };

    this.onClickImportEmployees = this.onClickImportEmployees.bind(this);
    this.closeImportEmployeesPopup = this.closeImportEmployeesPopup.bind(this);
    this.props.getCarLoyaltyProgram();
  }

  componentDidMount() {
    let param: any = {};

    if (TB_BLACKBOX_ENV === 'production') {
      param.confidence = 'production';
    }

    this.props.getFeatures(param);
    this.props.getLicensePricing(this.props.companyId);
    this.props.getExpenseSplitSetting(this.props.companyId);
    this.props.getOrganizationsProductsSettings(this.props.companyId);
    this.props.getBasicEconomyFeature(this.props.companyId);
    this.props.getFraudRule(this.props.companyId);
    this.props.getReimbursement(this.props.companyId);
    this.props.getCorporateRateCodes(this.props.companyId);
    this.props.getFlightTourCodes(this.props.companyId);
    this.props.getNegotiatedHotels(this.props.companyId);
    this.props.getActiveExpenseIntegration(this.props.companyId);
    this.props.getActiveExpenseManagenentIntegration(this.props.companyId);
    this.props.getSingle(this.props.companyId);
    this.props.getReimbursementSettings(this.props.companyId);
    this.props.getBasicEconomyFeature(this.props.companyId);
    this.props.getAgencySetting(this.props.companyId);
    this.props.getDefaultExpensePolicy(this.props.companyId);
    this.props.getBankAccount(this.props.companyId);
    this.props.getFlightBookingWindowFeature(this.props.companyId);
    this.props.getVerifiedForTravelSettings(this.props.companyId);
    this.props.getSpecialRateCodes(this.props.companyId);
    this.props.getCarbonEmissionOrgProduct(this.props.companyId);
    this.props.getCarbonEmissionProducts();
    this.props.getPaidPlanSetting(this.props.companyId);
  }

  componentWillUnmount() {
    this.props.resetFeatures();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.reloadPricing !== this.props.reloadPricing) {
      this.props.getLicensePricing(this.props.companyId);
    }
  }

  onClickImportEmployees() {
    this.setState({
      importEmployeesPopupOpen: !this.state.importEmployeesPopupOpen,
    });
  }

  closeImportEmployeesPopup() {
    this.props.resetImportEmployees();

    this.setState({
      importEmployeesPopupOpen: false,
    });
  }

  renderEmployees() {
    return (
      <EmployeeList
        onClickImportEmployees={this.onClickImportEmployees}
        departments={_.get(this.props.companyDetails, 'departments', [])}
        travelPolicies={_.get(this.props.companyDetails, 'travelPolicies', [])}
        expensePolicies={_.get(
          this.props.companyDetails,
          'expensePolicies',
          [],
        )}
        organizationId={this.props.companyId}
      />
    );
  }

  renderImportEmployeesPopup() {
    if (this.state.importEmployeesPopupOpen === false) {
      return null;
    }

    let content = <ImportEmployeesLoading />;
    if (this.props.importEmployees.validating === false) {
      if (this.props.importEmployees.validationReport === null) {
        content = <ImportEmployeesUpload />;
      } else {
        navigate(`/company/${this.props.companyDetails.id}/import-employees/`);
      }
    }

    return (
      <ImportEmployeesPopup
        popupOpen={this.state.importEmployeesPopupOpen}
        onClosePopup={this.closeImportEmployeesPopup}
        content={content}
      />
    );
  }

  handleTabChange = (event: any, value: any) => {
    this.setState({
      tabValue: value,
    });
  };

  handleFeatureChange = (feature: any, checked: boolean) => {
    let param = {
      organizationId: this.props.companyId,
      featureName: feature.name,
      confidence: feature.confidence,
      enabled: checked,
    };
    this.props.putFeatures(param);
  };

  handleBasicEconomyChange = (event: any) => {
    let param = {
      organizationId: this.props.companyId,
      enabled: !_.get(
        this.props,
        'basicEconomyFeature.feature.value.enabled',
        false,
      ),
    };

    this.props.putBasicEconomy(param);
  };

  handleFlightBookingWindowFeatureChange = (value: any, enabled: any) => {
    const advancedFlightFeature = _.get(
      this.props,
      'advancedFlightBookingFeature.id',
      '',
    );

    if (value && enabled) {
      this.props.putFlightBookingFeature({
        organizationId: this.props.companyId,
        value: value,
      });
    }

    if (advancedFlightFeature && !enabled) {
      this.props.deleteFlightBookingFeature(advancedFlightFeature);
    }
  };

  handleProductsOrganizationSettingsChange = (product: string) => {
    let param = {
      organizationId: this.props.companyId,
      settingId: _.get(this.props, 'organziationProductsSettings.id', ''),
      enabled: !_.includes(
        _.get(this.props, 'organziationProductsSettings.value', []),
        product,
      ),
      product,
    };

    this.props.putOrganizationsProductsSettings(param);
  };

  handleFraudRuleChange = (event: any) => {
    let param = {
      orgId: this.props.companyId,
      rule: event.target.value,
      primaryDomain: this.props.companyDetails.primaryDomain,
    };

    this.props.updateOrganizationFraudRule(param);
  };

  handleReimbursementScheduleChange = (event: any) => {
    const param = {
      frequency: event.target.value,
      organizationId: this.props.companyId,
    };

    this.props.putReimbursementSettings(param);
  };

  handleTrustedCompanyChange = (event: any) => {
    let param = {
      organizationId: this.props.companyId,
      settingId: _.get(this.props, 'reimbursementFeature.id', ''),
      enabled: !_.get(this.props, 'reimbursementFeature.value.trusted', false),
    };

    this.props.putReimbursement(param);
  };

  handleExpenseSplitChange = (event: any) => {
    const enabled = !_.get(
      this.props,
      'expenseSplitFeature.feature.value.enabled',
      false,
    );

    this.props.putExpenseSplitSetting(this.props.companyId, enabled);
  };

  handleVerifiedForTravelChange = () => {
    this.props.putVerifiedForTravelSettings({
      organizationId: this.props.companyId,
      enabled: !_.get(
        this.props,
        'verifiedForTravelFeature.value.enabled',
        false,
      ),
    });
  };

  renderFeatures = () => {
    const organziationProductsSettings = _.get(
      this.props,
      'organziationProductsSettings',
      [],
    );
    const trustedCompanyFeature = _.get(this.props, 'reimbursementFeature', []);

    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <CompanyFeatures
            expenseSplitFeature={_.get(
              this.props,
              'expenseSplitFeature.feature',
              {},
            )}
            handleExpenseSplitChange={this.handleExpenseSplitChange}
            isLoadingExpenseSplit={_.get(
              this.props,
              'expenseSplitFeature.isLoading',
              false,
            )}
            organziationProductsSettings={organziationProductsSettings}
            handleProductSettingsChange={() =>
              this.handleProductsOrganizationSettingsChange('expenses')
            }
          />

          <Reimbursements
            trustedCompanyFeature={trustedCompanyFeature}
            handleTrustedCompanyChange={this.handleTrustedCompanyChange}
            organizationName={_.get(this.props, 'companyDetails.name', '')}
            handleReimbursementScheduleChange={
              this.handleReimbursementScheduleChange
            }
            reimbursement={_.get(this.props, 'reimbursementSettings', {})}
            organizationBankAccount={_.get(
              this.props,
              'organizationBankAccount',
              {},
            )}
            organizationId={this.props.companyId}
          />
        </div>
        <div className={styles.sidebar}>
          {(_.get(
            this.props.organizationActiveExpenseIntegration,
            'provider',
            null,
          ) !== null ||
            _.get(
              this.props.organizationActiveExpenseManagement,
              'value',
              null,
            ) !== null) && (
            <ActiveExpenseIntegration
              provider={_.get(
                this.props.organizationActiveExpenseIntegration,
                'provider',
                '',
              )}
              expenseManagement={_.get(
                this.props.organizationActiveExpenseManagement,
                'value',
                [],
              )}
            />
          )}
        </div>
      </div>
    );
  };

  renderCreditCards = () => {
    return (
      <CorporateCards
        creditCards={_.get(this.props.companyDetails, 'creditCards', [])}
        organizationId={this.props.companyDetails.id}
        departments={_.get(this.props.companyDetails, 'departments', [])}
        onSaveCreditCard={this.handleCreditCardSaved}
        users={_.get(this.props.companyDetails, 'employees', [])}
        organizationName={_.get(this.props, 'companyDetails.name', '')}
      />
    );
  };

  renderBeta = () => {
    const features = _.get(this.props, 'features', []);
    return (
      <BetaFeatures
        features={features}
        organizationId={this.props.companyDetails.id}
        type="organization"
        onHandleChange={this.handleFeatureChange}
      />
    );
  };

  updateCarbonEmissionProduct = async (data: any) => {
    const {
      companyId: organizationId,
      addUpdateCarbonEmissionProduct: onUpdateCarbonEmissionProduct,
    } = this.props;

    await onUpdateCarbonEmissionProduct(organizationId, data);
  };

  renderCarbonEmissionProduct = () => {
    const {
      carbonEmissionOffset: {
        isLoading,
        productId = '',
        carbonOffsetProducts = [],
      },
    } = this.props || {};

    return (
      <CreateUpdateCarbonEmissionProduct
        isLoading={isLoading}
        productId={productId}
        products={carbonOffsetProducts}
        onUpdateCarbonEmissionProduct={this.updateCarbonEmissionProduct}
      />
    );
  };

  renderCorporateRateCodes = () => {
    return (
      <CorporateRateCodes
        putCorporateCodes={this.putCorporateRateCodes}
        flightCodes={_.get(
          this.props.corporateRateCodes,
          'corporateRateCodes.value.flights',
          [],
        )}
        carCodes={_.get(
          this.props.corporateRateCodes,
          'corporateRateCodes.value.cars',
          [],
        )}
        hotelCodes={_.get(
          this.props.corporateRateCodes,
          'corporateRateCodes.value.hotels',
          [],
        )}
        isLoading={_.get(this.props.corporateRateCodes, 'isLoading', false)}
        hasPermission={hasPermission('organizations', 'update')}
        organization={true}
        carLoyaltyProgram={this.props.carLoyaltyProgram}
      />
    );
  };

  putCorporateRateCodes = (params: any) => {
    params.organizationId = this.props.companyDetails.id;
    params.settingId = _.get(
      this.props.corporateRateCodes,
      'corporateRateCodes.id',
      '',
    );

    this.props.putCorporateRateCodes(params);
  };

  renderNegotiatedSuppliers = () => {
    return <NegotiatedSuppliers />;
  };

  renderNotes = () => {
    return (
      <Notes
        id={this.props.companyId}
        type="organization"
        entity={this.props.companyDetails}
      />
    );
  };

  renderTravelFeatures = () => {
    const organziationProductsSettings = _.get(
      this.props,
      'organziationProductsSettings',
      [],
    );

    return (
      <TravelFeatures
        handleFraudRuleChange={this.handleFraudRuleChange}
        fraudCheck={_.get(this.props, 'fraudCheck', 'fraud')}
        basicEconomyFeature={_.get(
          this.props,
          'basicEconomyFeature.feature',
          {},
        )}
        advancedFlightFeature={_.get(
          this.props,
          'advancedFlightBookingFeature',
          '',
        )}
        verifiedForTravelFeature={_.get(
          this.props,
          'verifiedForTravelFeature',
          '',
        )}
        organziationProductsSettings={organziationProductsSettings}
        handleBasicEconomyChange={this.handleBasicEconomyChange}
        handleVerifiedForTravelChange={this.handleVerifiedForTravelChange}
        handleFlightBookingWindowFeatureChange={
          this.handleFlightBookingWindowFeatureChange
        }
        handleProductSettingsChange={() =>
          this.handleProductsOrganizationSettingsChange('travel')
        }
      />
    );
  };

  renderOrgAgency = () => {
    return (
      <OrganizationAgencySetting
        agencyDetails={this.props.agencySetting}
        specialRateCodes={this.props.specialRateCodes}
        agency={_.get(this.props.companyDetails, 'agency', {})}
        organizationId={this.props.companyId}
      />
    );
  };

  handleCreditCardSaved = () => {
    this.props.getSingle(this.props.companyId);
  };

  renderBrexCustomer = () => {
    let isBrexcustomer = false;

    _.forEach(_.get(this.props, 'companyDetails.employees', []), (e) => {
      if (_.get(e, '_meta.brex', null) !== null) {
        isBrexcustomer = true;
        return false;
      }

      return;
    });

    return isBrexcustomer;
  };

  renderTab = () => {
    switch (this.state.tabValue) {
      case 'profile':
        return (
          <div className={styles.container}>
            <div className={styles.content}>
              <CompanyInfo
                company={this.props.companyDetails}
                disabled={!hasPermission('organizations', 'update')}
              />
              <AdditionalDomains
                company={this.props.companyDetails}
                hasPermission={hasPermission('organizations', 'update')}
              />
              {this.renderCreditCards()}
            </div>
            {_.get(this.props.companyDetails, 'agency.id', ' ') ==
              TB_AGENCY_ID && (
              <div className={styles.sidebar}>
                <OnboardingComp />
              </div>
            )}
          </div>
        );
        break;
      case 'plansAndPricing':
        return (
          <div>
            <LicensePricing
              company={this.props.companyDetails}
              licensePricing={this.props.licensePricing}
            />
            {this.renderRewardsProgram()}
          </div>
        );
        break;
      case 'expenses':
        return <div>{this.renderFeatures()}</div>;
        break;
      case 'travel':
        return (
          <div>
            {this.renderOrgAgency()}
            {this.renderTravelFeatures()}
            {this.renderCorporateRateCodes()}
            {this.renderCarbonEmissionProduct()}
            {this.renderNegotiatedSuppliers()}
            {this.renderNotes()}
          </div>
        );
        break;
      case 'beta':
        return <div>{this.renderBeta()}</div>;
        break;
      default:
        return (
          <div>
            {this.renderEmployees()}
            {this.renderImportEmployeesPopup()}
          </div>
        );
        break;
    }
  };

  renderRewardsProgram = () => {
    let isCommercialRewardsEnabled = false;

    if (
      this.props.isFeatureFlagsLoading === false &&
      this.props.isFeatureFlagsSuccess === true &&
      this.props.companyDetails.agency?.id
    ) {
      const featureFlag = this.props.features.find(
        (flag) => flag.name === 'commercialRewards',
      );

      if (!featureFlag) {
        return null;
      }

      isCommercialRewardsEnabled = isFeatureEnabled(
        featureFlag,
        ScopeType.AGENCY,
        this.props.companyDetails.agency?.id,
      );

      if (isCommercialRewardsEnabled === false) {
        return null;
      }

      return <RewardsProgram></RewardsProgram>;
    }

    return null;
  };

  render() {
    if (this.props.isLoading) {
      return (
        <LayoutDashboard>
          <div className="pageWrapper">
            <Loader
              visible={this.props.isLoading}
              id="companyLoader"
            />
          </div>
        </LayoutDashboard>
      );
    }

    return (
      <LayoutDashboard>
        <div>
          <Breadcrumbs className={styles.breadcrumbs}>
            <Breadcrumb
              name="Lookup"
              arrow
            />
            <Breadcrumb name={_.get(this.props, 'companyDetails.name', '')} />
          </Breadcrumbs>
        </div>

        <div className="pageWrapper pageWrapperMid">
          <div className={styles.companyNameContainer}>
            <h1>{_.get(this.props, 'companyDetails.name', '')}</h1>

            <div className={styles.chips}>
              {_.get(this.props.companyDetails, 'agency.friendlyName', null) !==
                null && (
                <div
                  style={{
                    background: _.get(
                      this.props.companyDetails,
                      'agency.uiSettings.primaryColor',
                      '#6d717a',
                    ),
                  }}
                  className={styles.agency}
                >
                  {this.props.companyDetails.agency.friendlyName}
                </div>
              )}
              {this.renderBrexCustomer() && (
                <div className={styles.brex}>Brex</div>
              )}
              {_.get(this.props, 'fraudCheck') === 'emailDomainBlockList' && (
                <div className={styles.fraud}>Fraud</div>
              )}
            </div>
          </div>
          <Tabs
            value={this.state.tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleTabChange}
            className={cx(styles.tabs, styles.loyalityTabs)}
          >
            <Tab
              disableRipple
              className={styles.tab}
              label="Profile"
              value="profile"
            />
            <Tab
              disableRipple
              className={styles.tab}
              label="Plans & Pricing"
              value="plansAndPricing"
            />
            <Tab
              disableRipple
              className={styles.tab}
              label="Beta"
              value="beta"
            />
            <Tab
              disableRipple
              className={styles.tab}
              label="Expenses"
              value="expenses"
            />
            <Tab
              disableRipple
              className={styles.tab}
              label="Travel"
              value="travel"
            />
            <Tab
              disableRipple
              className={styles.tab}
              label="Employees"
              value="employees"
              id="employeeTab"
            />
          </Tabs>
          {this.renderTab()}
        </div>
      </LayoutDashboard>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    importEmployees: state.importEmployees,
    companyDetails: state.company.company,
    isLoading: state.company.isLoading,
    features: state.betaFeatures.features,
    isFeatureFlagsLoading: state.features.isLoading,
    isFeatureFlagsSuccess: state.features.success,
    updateSuccess: state.betaFeatures.success,
    expenseSplitFeature: state.expenseSplitSetting,
    organziationProductsSettings: state.organizationProductsSettings.products,
    reimbursementFeature: state.organizationTrustedCompanyFeature.feature,
    reimbursementSettings: state.reimbursementSettings.feature,
    corporateRateCodes: state.corporateRateCodes,
    fraudCheck: state.organizationFraudRule.rule,
    basicEconomyFeature: state.organizationBasicEconomyFeature,
    organizationActiveExpenseIntegration:
      state.organizationActiveExpenseIntegration,
    agencySetting: state.agencySetting.agencySetting,
    organizationActiveExpenseManagement:
      state.organizationActiveExpenseManagement,
    expensePolicy: state.expensePolicy.expensePolicy,
    carLoyaltyProgram: state.carLoyaltyPrograms.carLoyaltyProgram,
    organizationBankAccount: state.organizationBankAccount.bankAccount,
    advancedFlightBookingFeature:
      state.organizationFlightBookingWindowFeature.feature,
    verifiedForTravelFeature: state.verifiedForTravelFeature.feature,
    licensePricing: state.licensePricingSetting.licensePricing,
    reloadPricing: state.licensePricingSetting.reloadPricing,
    specialRateCodes: state.specialRateCodes.specialRateCodes,
    carbonEmissionOffset: state.carbonEmissionOffset,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getSingle,
      resetImportEmployees,
      confirmCSVImport,
      getFeatures,
      getExpenseSplitSetting,
      putExpenseSplitSetting,
      getOrganizationsProductsSettings,
      getBasicEconomyFeature,
      putBasicEconomy,
      putOrganizationsProductsSettings,
      getReimbursement,
      putReimbursement,
      getReimbursementSettings,
      putReimbursementSettings,
      getCorporateRateCodes,
      getFlightTourCodes,
      getNegotiatedHotels,
      getActiveExpenseIntegration,
      getActiveExpenseManagenentIntegration,
      resetFeatures,
      putFeatures,
      getFraudRule,
      updateOrganizationFraudRule,
      getAgencySetting,
      putCorporateRateCodes,
      getDefaultExpensePolicy,
      getCarLoyaltyProgram,
      getBankAccount,
      getFlightBookingWindowFeature,
      deleteFlightBookingFeature,
      putFlightBookingFeature,
      putVerifiedForTravelSettings,
      getVerifiedForTravelSettings,
      getLicensePricing,
      getSpecialRateCodes,
      getCarbonEmissionProducts,
      getCarbonEmissionOrgProduct,
      addUpdateCarbonEmissionProduct,
      getPaidPlanSetting,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
