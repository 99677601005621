exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".components-SupportLogin-styles___supportLoginButton--lvMkw {\n  padding: 0 16px;\n  background-color: rgb(49, 138, 251);\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  height: 32px;\n  font-size: 13px;\n  margin-left: 16px;\n}\n\n.components-SupportLogin-styles___supportLoginButton--lvMkw:hover {\n  background-color: #0056b3;\n}\n", "", {"version":3,"sources":["/tmp/build_c0b3ce23/src/components/SupportLogin/styles.pcss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,oCAAoC;EACpC,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,gBAAgB;EAChB,kBAAkB;CACnB;;AAED;EACE,0BAA0B;CAC3B","file":"styles.pcss","sourcesContent":[".supportLoginButton {\n  padding: 0 16px;\n  background-color: rgb(49, 138, 251);\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  height: 32px;\n  font-size: 13px;\n  margin-left: 16px;\n}\n\n.supportLoginButton:hover {\n  background-color: #0056b3;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"supportLoginButton": "components-SupportLogin-styles___supportLoginButton--lvMkw"
};