import {
  ACTION_GET_USER_TRAVEL_POLICY_RULES,
  ACTION_GET_USER_TRAVEL_POLICY_RULES_SUCCESS,
  ACTION_GET_USER_TRAVEL_POLICY_RULES_FAILURE,
  ACTION_EMPTY_USER_TRAVEL_POLICY_RULES,
  ACTION_EMPTY_USER_TRAVEL_POLICY_SUCCESS_PROPERTY,
} from '../configs/ActionTypes';

const initialState = {
  travelPolicyRules: null,
  travelPolicyFlight: null,
  isLoading: false,
  success: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACTION_GET_USER_TRAVEL_POLICY_RULES: {
      return {
        ...state,
        isLoading: true,
        success: null,
      };
    }

    case ACTION_GET_USER_TRAVEL_POLICY_RULES_SUCCESS: {
      let hotelPolicy: any = state.travelPolicyRules;
      let flightPolicy: any = state.travelPolicyFlight;

      if (action.payload.payload.type == 'hotel') {
        hotelPolicy = action.payload.payload;
      } else {
        flightPolicy = action.payload.payload;
      }

      return {
        ...state,
        travelPolicyRules: hotelPolicy,
        travelPolicyFlight: flightPolicy,
        isLoading: false,
        success: true,
      };
    }

    case ACTION_GET_USER_TRAVEL_POLICY_RULES_FAILURE: {
      return {
        ...state,
        travelPolicyRules: null,
        isLoading: false,
        success: true,
      };
    }

    case ACTION_EMPTY_USER_TRAVEL_POLICY_RULES: {
      return {
        ...state,
        travelPolicyRules: null,
        isLoading: false,
        success: false,
      };
    }

    case ACTION_EMPTY_USER_TRAVEL_POLICY_SUCCESS_PROPERTY: {
      return {
        ...state,
        success: null,
      };
    }

    default: {
      return state;
    }
  }
}
