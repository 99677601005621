import TableCell from '@material-ui/core/TableCell';
import * as React from 'react';
import classNames from 'classnames';
const styles = require('./styles.pcss');

interface Props {
  card: any;
}

export default function DefaultCell(props: Props) {
  let defaultText = '';
  if (props.card.default) {
    defaultText = props.card.defaultDepartmentId
      ? 'Department Default'
      : 'Default';
  }
  return (
    <TableCell
      key={'default' + props.card.id}
      className={classNames(styles.tableCell)}
    >
      {defaultText}
    </TableCell>
  );
}
