import * as React from 'react';
import * as _ from 'lodash-es';
import { navigate } from '@reach/router';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Avatar from '@material-ui/core/Avatar';

import SvgIconAvatar from '../../static/icons/svgIconAvatar';
import LayoutDashboard from 'components/Layout/Dashboard';
import Loader from 'components/Loader/Loader';
import PersonalDetails from 'components/TravelerDetails/PersonalDetails';
import LoyaltyPrograms from 'components/TravelerDetails/LoyaltyPrograms';
import FlightCredits from 'components/TravelerDetails/FlightCredits';
import PaymentMethods from 'components/TravelerDetails/PaymentMethods';
import BookingRules from 'components/TravelerDetails/TravelPolicy/BookingRules';
import BudgetRules from 'components/TravelerDetails/TravelPolicy/BudgetRules';
import NotificationsAndApprovals from 'components/TravelerDetails/TravelPolicy/NotificationsAndApprovals';
import AccountDetails from 'components/TravelerDetails/AccountDetails';
import BookingsTable from 'components/TravelerDetails/BookingsTable';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/Breadcrumbs/Breadcrumb';
import Approvers from 'components/TravelerDetails/TravelPolicy/Approvers';
import ExpenseReportsTable from 'components/TravelerDetails/ExpenseReportsTable';
import DuffelSync from './DuffelSync';
import { hasPermission } from 'helpers/Permission';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getSingle } from 'actions/Travelers/GetSingle';
import { getFrequentFlyers } from 'actions/FrequentFlyers/Get';
import { update as updateTraveler } from 'actions/Travelers/Update';
import {
  getTravelPolicyRules,
  emptyResults as resetTravelPolicy,
  resetSuccessProperty as resetTravelPolicySuccessProp,
} from 'actions/Users/GetTravelPolicyRules';
import { getExpenseReports } from 'actions/Users/GetExpenseReports';
import { getBudgetPolicyRules } from 'actions/Users/GetBudgetPolicyRules';
import { get as getCreditCards } from 'actions/User/GetUserCreditCards';
import { getUserFlightCredits } from 'actions/User/GetUserFlightCredits';
import { getApprovers } from 'actions/Travelers/GetApprovers';
import { getCarLoyaltyProgram } from '../../actions/LoyaltyPrograms/GetCarLoyaltyProgram';
import { getFraudRule } from 'actions/Users/GetUserFraudRule';
import { putFeatures } from 'actions/Features/PutUserBetaFeature';
import { getFeatures, resetFeatures } from 'actions/Features/Get';
import { syncDuffelBooking } from 'actions/Bookings/SyncDuffelBooking';
import BetaFeatures from 'components/Features/BetaFeatures';
import { TB_BLACKBOX_ENV } from '../../configs/Api';
import Notes from 'components/Notes/Notes';

const styles = require('./styles.pcss');

import { ReduxState, Traveler as TravelerType } from 'reducers/types';
import BookTrain from 'components/BookTrain/BookTrain';
import { postTrainBooking } from 'actions/Bookings/PostTrainBooking';
import SupportLoginButton from 'components/SupportLogin/SupportLogin';

type Props = {
  travelerId?: string;
  getSingle: Function;
  postTrainBooking: Function;
  updateTraveler: Function;
  getFrequentFlyers: Function;
  traveler: TravelerType;
  isLoading: boolean;
  isLoadingCC: any;
  getTravelPolicyRules: Function;
  getExpenseReports: Function;
  user: any;
  userLoading: any;
  travelPolicy: any;
  budgetPolicy: any;
  resetTravelPolicy: Function;
  resetTravelPolicySuccessProp: Function;
  userId: any;
  travelers: any;
  getCreditCards: Function;
  getUserFlightCredits: Function;
  getApprovers: Function;
  approversIsLoading: boolean;
  approversList: any;
  carLoyaltyProgram: any;
  expenseReports: any;
  getCarLoyaltyProgram: Function;
  getBudgetPolicyRules: Function;
  getFraudRule: Function;
  syncDuffelBooking: Function;
  getFeatures: Function;
  resetFeatures: Function;
  putFeatures: Function;
  isLoadingFeatures: boolean;
  features: any;
  isLoadingDuffelSync: boolean;
  isLoadingBooking: boolean;
};

enum TabTypes {
  travelPolicy = 'travel-policy',
  creditCards = 'credit-cards',
  profile = 'profile',
  bookings = 'bookings',
  expenses = 'expenses',
  account = 'account',
  beta = 'beta',
}

type State = {
  isUpdatedTravelPolicyEnabled: boolean;
  isFlagUpdated: boolean;
  activeTab: TabTypes;
};

class UserDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const searchParams = new URLSearchParams(window.location.search);
    const activeTab =
      (searchParams.get('activeTab') as TabTypes) || TabTypes.profile;

    this.state = {
      activeTab: activeTab,
      isUpdatedTravelPolicyEnabled: false,
      isFlagUpdated: false,
    };

    this.onTabChange = this.onTabChange.bind(this);

    let param: any = {
      scopeType: 'user',
      name: 'travelPolicy.updated',
      queryType: 'or',
    };

    if (TB_BLACKBOX_ENV === 'production') {
      param.confidence = TB_BLACKBOX_ENV;
    }

    if (hasPermission('features', 'read')) {
      this.props.getFeatures(param);
    }
  }

  onTabChange(event: any, value: any) {
    window.history.pushState({}, '', `?activeTab=${value}`);

    if (value === TabTypes.profile) {
      if (this.props.travelers.travelers.length > 0) {
        let traveler = _.find(
          this.props.travelers.travelers,
          (t) => t.isPrimary === true,
        );

        if (traveler !== undefined && traveler.hasOwnProperty('id')) {
          this.props.getSingle(traveler.id);
        }
      }
    }

    if (value === TabTypes.expenses && hasPermission('expenseReport', 'read')) {
      this.props.getExpenseReports(this.props.userId);
    }

    this.setState({
      activeTab: value,
    });
  }

  componentDidMount() {
    this.props.getFraudRule(this.props.userId);
    this.props.resetTravelPolicy();
    this.props.getFrequentFlyers();
    this.props.getCarLoyaltyProgram();
    if (hasPermission('bookings', 'read')) {
      this.props.getUserFlightCredits(this.props.userId);
    }
    if (this.props.travelerId && this.props.travelerId !== null) {
      this.props.getSingle(this.props.travelerId);
    }
  }

  componentWillUnmount() {
    this.props.resetTravelPolicy();
    this.props.resetFeatures();
  }

  componentDidUpdate = (prevProps: Props) => {
    navigate(`?activeTab=${this.state.activeTab}`);

    const isTravelerIdUndefined =
      !this.props.travelerId || this.props.travelerId === null;
    if (
      this.props.travelers?.travelers?.length &&
      prevProps.travelers != this.props.travelers &&
      isTravelerIdUndefined
    ) {
      this._onFetchTraveler();
    }

    const isOrganizationFeaturesPopulated =
      !!this.props?.user?.organizations?.length &&
      !!this.props.features?.length;
    if (!this.state.isFlagUpdated && isOrganizationFeaturesPopulated) {
      this._onSetTravelPolicyFF();
    }

    if (
      this.state.isFlagUpdated &&
      hasPermission('users', 'read') &&
      !this.props.travelPolicy.success &&
      !this.props.travelPolicy.isLoading
    ) {
      this._onFetchTravelPolicyRules();
    }

    if (
      this.state.isFlagUpdated &&
      !this.state.isUpdatedTravelPolicyEnabled &&
      !this.props.budgetPolicy.isLoading &&
      !this.props.travelPolicy.success &&
      !this.props.budgetPolicy.policyRules
    ) {
      const oldUserTravelPolicyId = _.get(
        this.props.user,
        'oldTravelPolicyId',
        '',
      );
      const userTravelPolicyId = _.get(this.props.user, 'travelPolicyId', '');
      if (oldUserTravelPolicyId || userTravelPolicyId) {
        this.props.getBudgetPolicyRules(
          oldUserTravelPolicyId || userTravelPolicyId,
        );
      }
    }

    if (
      this.state.activeTab == TabTypes.travelPolicy &&
      !_.get(this.props.user, 'travelPolicyId', '') &&
      !_.get(this.props.user, 'oldTravelPolicyId', '') &&
      this.props.travelPolicy.success &&
      !this.props.budgetPolicy.isLoading &&
      !this.props.budgetPolicy.policyRules
    ) {
      const defaultPolicyId =
        _.get(this.props.travelPolicy, 'travelPolicyRules.id', '') ||
        _.get(this.props.travelPolicy, 'travelPolicyFlight.id', '');

      if (defaultPolicyId) {
        this.props.getBudgetPolicyRules(defaultPolicyId);
        this.props.resetTravelPolicySuccessProp();
      }
    }
  };

  _onFetchTravelPolicyRules = () => {
    this.props.getApprovers(this.props.userId);

    const { isUpdatedTravelPolicyEnabled } = this.state;
    if (isUpdatedTravelPolicyEnabled) {
      // @NOTE: If isUpdatedTravelPolicyEnabled is enabled, we only need to call
      // getTravelPolicyRules once as the new structure includes both hotel & flight travel policies
      this.props.getTravelPolicyRules(this.props.userId);
    }

    if (!isUpdatedTravelPolicyEnabled) {
      this.props.getTravelPolicyRules(this.props.userId, 'hotel');
      this.props.getTravelPolicyRules(this.props.userId, 'flight');
    }
  };

  _onFetchTraveler = () => {
    let traveler = _.find(
      this.props.travelers.travelers,
      (t) => t.isPrimary === true,
    );
    if (traveler !== undefined && traveler.hasOwnProperty('id')) {
      this.props.getSingle(traveler.id);
    }
  };

  _onSetTravelPolicyFF = () => {
    const travelPolicyUpdatedFeature = (this.props.features as any[]).find(
      (feature) => feature?.name === 'travelPolicy.updated',
    );

    let isUpdatedTravelPolicyEnabled = false;
    if (travelPolicyUpdatedFeature) {
      const orgId = _.get(this.props.user?.organizations[0], 'id', null);
      const { organizations = [] } = travelPolicyUpdatedFeature?.scope;
      isUpdatedTravelPolicyEnabled = organizations.includes(orgId);
    }

    this.setState({
      isUpdatedTravelPolicyEnabled,
      isFlagUpdated: true,
    });
  };

  _onGetReadPermissions() {
    const loginData: any = localStorage.getItem('login_data');

    if (loginData !== null) {
      const loginDataperse = JSON.parse(loginData);
      const userPermissions = _.get(loginDataperse, 'userPermissions', null);
      const updatePermission = _.result(
        _.find(userPermissions, (p) => p.resource == 'travelers'),
        'update',
      );
      const createPermission = _.result(
        _.find(userPermissions, (p) => p.resource == 'travelers'),
        'create',
      );
      const readPermission = _.result(
        _.find(userPermissions, (p) => p.resource == 'travelers'),
        'read',
      );

      if (!updatePermission && !createPermission && readPermission) {
        return true;
      }
    }

    return false;
  }

  _onUpdateTravelerInfo = (traveler: any) => {
    return {
      travelerId: traveler.id,
      firstName: traveler.firstName,
      middleName: traveler.middleName,
      lastName: traveler.lastName,
      gender: traveler.gender,
      birthDate: traveler.birthDate,
      knownTravelerNumber: traveler.knownTravelerNumber,
      email: traveler.email,
      phone: {
        countryCode: traveler.phoneCountryCode,
        number: traveler.phoneNumber,
      },
      passportInfo: {
        passCountry: traveler.passCountry,
        passNumber: traveler.passNumber,
        passExpiry: traveler.passExpiry,
      },
    };
  };

  _onUpdateFrequentFlyers = (frequentFlyers: any) => {
    const clonedTraveler = _.clone(this.props.traveler);
    const updatedTravelerInfo = this._onUpdateTravelerInfo(clonedTraveler);

    this.props.updateTraveler({
      ...updatedTravelerInfo,
      frequentFlyer: frequentFlyers,
    });
  };

  _onUpdateHotelLoyaltyProgram = (hotelLoyaltyPrograms: any) => {
    const clonedTraveler = _.clone(this.props.traveler);
    const updatedTravelerInfo = this._onUpdateTravelerInfo(clonedTraveler);

    this.props.updateTraveler({
      ...updatedTravelerInfo,
      frequentFlyer: clonedTraveler.frequentFlyer,
      hotelLoyaltyPrograms: hotelLoyaltyPrograms,
    });
  };

  _onUpdateCarLoyaltyProgram = (carLoyalty: any) => {
    const clonedTraveler = _.clone(this.props.traveler);
    const updatedTravelerInfo = this._onUpdateTravelerInfo(clonedTraveler);

    this.props.updateTraveler({
      ...updatedTravelerInfo,
      carLoyaltyPrograms: carLoyalty,
    });
  };

  _onCreditCardsChanged = () => this.props.getCreditCards(this.props.userId);

  _onRenderCustomerDetails = () => {
    const createPermission = hasPermission('travelers', 'create');

    const newTraveler =
      _.get(this.props.travelers, 'travelers', []).length == 0 &&
      createPermission;

    return (
      <PersonalDetails
        traveler={this.props.traveler}
        newTraveler={newTraveler}
        userId={this.props.userId}
        readOnly={this._onGetReadPermissions()}
      />
    );
  };

  _onRenderFlightCredits = () =>
    _.get(this.props, 'traveler', false) && (
      <FlightCredits
        flightCredits={_.get(this.props.user, 'flightCredits', [])}
        hasPermission={hasPermission('travelers', 'update')}
      />
    );

  _onRenderNotes = () => (
    <Notes id={this.props.userId} type="user" entity={this.props.user} />
  );

  _onHandleFeatureChange = (feature: any, checked: boolean) => {
    this.props.putFeatures({
      userId: _.get(this.props, 'userId', null),
      featureName: feature.name,
      confidence: feature.confidence,
      enabled: checked,
      orgId: _.get(this.props.user?.organizations[0], 'id', null),
    });
  };

  _onRenderContent() {
    const { activeTab, isUpdatedTravelPolicyEnabled } = this.state;
    if (activeTab == TabTypes.profile) {
      return (
        <div>
          {this._onRenderCustomerDetails()}
          {_.get(this.props, 'traveler', false) && (
            <LoyaltyPrograms
              travelerFrequentFlyerData={_.get(
                this.props,
                'traveler.frequentFlyer',
                [],
              )}
              travelerHotelLoyaltyProgram={_.get(
                this.props,
                'traveler.hotelLoyaltyPrograms',
                [],
              )}
              updateHotelProgram={this._onUpdateHotelLoyaltyProgram}
              update={this._onUpdateFrequentFlyers}
              hasPermission={hasPermission('bookings', 'update')}
              carLoyaltyProgram={this.props.carLoyaltyProgram}
              travelerCarLoyaltyProgram={_.get(
                this.props,
                'traveler.carLoyalty',
                [],
              )}
              updateCarProgram={this._onUpdateCarLoyaltyProgram}
            />
          )}
          {this._onRenderFlightCredits()}
          {this._onRenderNotes()}
        </div>
      );
    }

    if (activeTab == TabTypes.creditCards) {
      return (
        <div>
          <PaymentMethods
            creditCards={_.get(this.props.user, 'creditCards', [])}
            userId={this.props.userId}
            onCreditCardsChanged={this._onCreditCardsChanged}
          />
        </div>
      );
    }

    if (activeTab === TabTypes.travelPolicy) {
      return (
        <div className={styles.travelPolicyRulesRow}>
          <div className={styles.travelPolicyRules}>
            <BudgetRules
              isUpdatedTravelPolicyEnabled={isUpdatedTravelPolicyEnabled}
              isLoading={
                this.props.budgetPolicy.isLoading ||
                this.props.travelPolicy.isLoading
              }
              policyRules={this.props.budgetPolicy.policyRules}
              budgetPolicy={
                this.props.travelPolicy.travelPolicyFlight?.budgetPolicy
              }
            />
            <BookingRules
              isUpdatedTravelPolicyEnabled={isUpdatedTravelPolicyEnabled}
              isLoading={this.props.travelPolicy.isLoading}
              hotelPolicy={this.props.travelPolicy.travelPolicyRules}
              flightPolicy={this.props.travelPolicy.travelPolicyFlight}
            />
            <NotificationsAndApprovals
              isUpdatedTravelPolicyEnabled={isUpdatedTravelPolicyEnabled}
              isLoading={this.props.travelPolicy.isLoading}
              hotelPolicy={this.props.travelPolicy.travelPolicyRules}
              flightPolicy={this.props.travelPolicy.travelPolicyFlight}
            />
          </div>
          <div>
            <Approvers
              loading={this.props.approversIsLoading}
              approversList={this.props.approversList}
            />
          </div>
        </div>
      );
    }

    if (activeTab == TabTypes.bookings) {
      return (
        <div>
          <Paper className={styles.syncPaper}>
            <DuffelSync
              userId={this.props.userId}
              sync={this.props.syncDuffelBooking}
              disabled={this.props.isLoadingDuffelSync}
            />
          </Paper>
          <Paper className={styles.syncPaper}>
            <BookTrain
              onSubmit={this.props.postTrainBooking}
              userId={this.props.userId}
              disabled={this.props.isLoadingBooking}
            />
          </Paper>
          <Paper className={styles.paper}>
            <BookingsTable userId={this.props.userId} />
          </Paper>
        </div>
      );
    }

    if (activeTab == TabTypes.expenses) {
      return (
        <Paper className={styles.paper}>
          <ExpenseReportsTable userId={this.props.userId} />
        </Paper>
      );
    }

    if (activeTab == TabTypes.account) {
      return (
        <div>
          <AccountDetails user={this.props.user} userId={this.props.userId}/>
        </div>
      );
    }

    if (activeTab == TabTypes.beta) {
      const features = _.get(this.props, 'features', []);

      return (
        <BetaFeatures
          features={features}
          userId={_.get(this.props, 'userId', null)}
          type="user"
          onHandleChange={this._onHandleFeatureChange}
        />
      );
    }

    return null;
  }

  renderAvatar = () => {
    if (this.props.isLoading || this.props.userLoading) {
      return <Avatar alt="Avatar" className={styles.avatar}></Avatar>;
    }

    if (
      _.get(this.props.user, 'avatar', '').length > 1 &&
      _.get(this.props.user, 'avatar', '') != null
    ) {
      return (
        <Avatar
          alt="Avatar"
          src={_.get(this.props.user, 'avatar', '')}
          className={styles.avatar}
        />
      );
    }

    const firstName =
      _.get(this.props, 'user.firstName', null) !== null
        ? _.get(this.props, 'user.firstName', null)
        : '';
    const lastName =
      _.get(this.props, 'user.lastName', null) !== null
        ? _.get(this.props, 'user.lastName', null)
        : '';

    const name = `${firstName} ${lastName}`;
    if (name.trim() === '') {
      return <SvgIconAvatar width={50} height={50} />;
    }

    return (
      <Avatar alt="avatar" className={styles.avatarLetter}>
        {firstName.charAt(0)}
        {lastName.charAt(0)}
      </Avatar>
    );
  };

  _onGetUserName = () => {
    let name = `${_.get(this.props.user, 'firstName', '')} ${_.get(
      this.props.user,
      'lastName',
      '',
    )}`;

    if (name.trim() === '') {
      return _.get(this.props.user, 'email', '');
    }

    return name;
  };

  _onRenderFraudStatus = () => {
    const userFraudStatus = _.get(this.props, 'fraudCheck');
    const orgFraudStatus = _.get(this.props, 'user.organizations', '')?.[0]
      ?.fraudStatus;
    if (userFraudStatus === 'emailBlockList') {
      return <div className={styles.fraud}>Fraud</div>;
    }

    if (orgFraudStatus === 'fraudulent') {
      return <div className={styles.fraud}>Fraud Org</div>;
    }

    return;
  };

  render() {
    let title =
      _.get(this.props, 'traveler.firstName', '') +
      ' ' +
      _.get(this.props, 'traveler.lastName', '');

    if (
      ![
        TabTypes.profile,
        TabTypes.creditCards,
        TabTypes.travelPolicy,
        TabTypes.beta,
      ].includes(this.state.activeTab)
    ) {
      title = 'Bookings for ' + title;
    }

    const orgs = _.uniqBy(_.get(this.props.user, 'organizations', []), 'id');
    const channels = _.get(this.props.user, 'channels', []);

    return (
      <LayoutDashboard>
        {(this.props.isLoading ||
          this.props.userLoading ||
          this.props.isLoadingCC) && (
          <div className={styles.detailsLoaderContainer}>
            <Loader visible id="userLoader" />
          </div>
        )}

        <div className="pageWrapper">
          <Breadcrumbs className={styles.breadcrumbs}>
            <Breadcrumb arrow name="Lookup" />
            <Breadcrumb name={this._onGetUserName()} />
          </Breadcrumbs>
          <div className={styles.header}>
            <div className={styles.avatarAndName}>
              <div className={styles.avatarContainer}>
                {this.renderAvatar()}
              </div>
              <div>
                <div className={styles.nameAndBrexContainer}>
                  <h1 className={styles.travelerName}>
                    {this._onGetUserName()}
                  </h1>

                  <div className={styles.chips}>
                    {_.get(this.props.user, 'agency.friendlyName', null) !==
                      null && (
                      <div
                        style={{
                          background: _.get(
                            this.props.user,
                            'agency.uiSettings.primaryColor',
                            '#6d717a',
                          ),
                        }}
                        className={styles.chip}
                      >
                        {this.props.user.agency.friendlyName}
                      </div>
                    )}
                    {channels.map((channel: any) => (
                      <div
                        key={channel.id}
                        className={`${styles.chip}`}
                        style={{ background: channel.uiSettings.primaryColor }}
                      >
                        {channel.friendlyName}
                      </div>
                    ))}
                    {_.get(this.props.user, 'status', '') == 'disabled' && (
                      <div className={`${styles.chip} ${styles.terminated}`}>
                        Terminated
                      </div>
                    )}
                    {this._onRenderFraudStatus()}
                    {hasPermission('customerLogin', 'read') && this.props.userId && <SupportLoginButton userId={this.props.userId} />}
                  </div>
                </div>

                <div className={styles.companyName}>
                  {orgs.map((organization: any, i: number) => {
                    const compName = _.get(organization, 'name', '');

                    return (
                      <>
                        <div
                          key={_.get(organization, 'id', '')}
                          className={styles.link}
                          onClick={() => {
                            navigate(
                              `/company/${_.get(organization, 'id', '')}`,
                            );
                          }}
                        >
                          {compName}{' '}
                        </div>
                        {i + 1 < orgs.length && ', '}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <Tabs
            className={styles.tabs}
            value={this.state.activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.onTabChange}
          >
            {hasPermission('travelers', 'read') && (
              <Tab className={styles.tab} label="Profile" value="profile" />
            )}
            {hasPermission('features', 'read') && (
              <Tab
                disableRipple
                className={styles.tab}
                label="Beta"
                value="beta"
              />
            )}
            {hasPermission('creditCards', 'read') && (
              <Tab
                className={styles.tab}
                label="Payment"
                id="payment"
                value="credit-cards"
              />
            )}
            {hasPermission('users', 'read') && (
              <Tab
                className={styles.tab}
                label="Travel Policy"
                value="travel-policy"
              />
            )}
            {hasPermission('bookings', 'read') && (
              <Tab className={styles.tab} label="Bookings" value="bookings" />
            )}
            {hasPermission('expenseReport', 'read') && (
              <Tab className={styles.tab} label="Expenses" value="expenses" />
            )}
            {hasPermission('users', 'read') && (
              <Tab
                className={styles.tab}
                label="Account"
                id="account-tab"
                value="account"
              />
            )}
          </Tabs>

          {this._onRenderContent()}
        </div>
      </LayoutDashboard>
    );
  }
}

// Wire up redux state to component
function mapStateToProps(state: ReduxState) {
  return {
    traveler: state.traveler.traveler,
    isLoading: state.traveler.isLoading,
    user: state.user.user,
    isLoadingCC: state.user.isLoadingCC,
    userLoading: state.user.isLoading,
    travelPolicy: state.userTravelPolicyRules,
    budgetPolicy: state.userBudgetPolicyRules,
    travelers: state.travelers,
    approversList: state.approvers.users,
    approversIsLoading: state.approvers.isLoading,
    carLoyaltyProgram: state.carLoyaltyPrograms.carLoyaltyProgram,
    expenseReports: state.userExpenseReports.expenseReports,
    fraudCheck: state.userFraudRule.rule,
    features: state.betaFeatures.features,
    isLoadingFeatures: state.betaFeatures.isLoading,
    isLoadingDuffelSync: state.syncDuffelbooking.isLoading,
    isLoadingBooking: state.booking.isLoading,
  };
}

// Wire up redux dispatch functions
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getSingle,
      getFrequentFlyers,
      updateTraveler,
      getTravelPolicyRules,
      getBudgetPolicyRules,
      getExpenseReports,
      resetTravelPolicy,
      resetTravelPolicySuccessProp,
      getCreditCards,
      getUserFlightCredits,
      getApprovers,
      getCarLoyaltyProgram,
      getFraudRule,
      resetFeatures,
      putFeatures,
      getFeatures,
      syncDuffelBooking,
      postTrainBooking,
    },
    dispatch,
  );
}

// Connect it to Redux
export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
