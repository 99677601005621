export const TRANSACTION_TYPES = {
  brexPoints: 'brexPoints',
  nitraPoints: 'nitraPoints',
  creditCard: 'creditCard',
};

export const TRANSACTION_AMOUNT_TYPES = {
  points: 'points',
  nitraPoints: 'nitraPoints',
  brexPoints: 'brexPoints',
};

export const TRANSACTION_PURPOSE_TYPES = {
  savings: 'savings',
  refund: 'refund',
};
